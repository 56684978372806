import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './common/common.css'

require('vue2-animate/dist/vue2-animate.min.css')

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    window.document.title = to.meta.title
  } else {
    window.document.title = '京孚-北京京孚信息技术有限公司'
  }
  next()
})

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')