<template>
  <div
    class="business-container"
    :class="_isMobile() ? 'introduce-mobile' : ''"
    v-cloak
    @click="clickEvent"
  >
    <div class="left-img"></div>
    <div class="right-img"></div>
    <div class="bus-content" v-cloak>
      <div class="bus-top" v-cloak>
        <div class="bus-top-left-txt01">企业资质</div>
        <div class="bus-detail">
          我们以客户需求和产品发展理念为驱动力，将战略合作联盟视为业务持续发展的重要基础，紧密结合技术创新和产品优化，建立自己品牌专业度，满足客户的多样化需求。
        </div>
      </div>
      <div class="bus-bottom">
        <div class="button" @click="preButton">
          <div class="pre-img"></div>
        </div>
        <swiper
          class="swiper-container"
          id="creSwiper"
          ref="creSwiper"
          :options="creSwiperOptions"
        >
          <swiper-slide class="swiper-slide">
            <div class="slide-img">
              <img src="../../assets/cre001.png" />
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <div class="slide-img">
              <img src="../../assets/cre002.png" />
            </div>
          </swiper-slide>
        </swiper>
        <div class="button" @click="nextButton()">
          <div class="next-img"></div>
        </div>
      </div>
    </div>
    <div class="next-button"></div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

export default {
  components: { swiper, swiperSlide },
  data() {
    return {
      mini_last: false,
      mini_first: false,
      current_index: 0,
      isAnimate: false,
      creSwiperOptions: {
        direction: 'horizontal',
        loop: true,
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        slidesPerView: 1,
        spaceBetween: 0,
        on: {
          slideChange: () => {
            this.current_index = this.swiper.activeIndex
          },
        },
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.creSwiper.swiper
    },
  },

  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
    clickEvent() {},

    preButton() {
      this.swiper.slideTo(1, 500, false)
      this.current_index = this.swiper.activeIndex
    },
    nextButton() {
      this.swiper.slideTo(0, 500, false)
      this.current_index = this.swiper.activeIndex
    },
  },
}
</script>

<style scoped>
@font-face {
  font-family: Bebas;
  src: url('../../assets/font/BEBAS___.TTF') format('truetype');
}

div {
  box-sizing: border-box;
}
.introduce-mobile {
  height: 1080px !important;
}

.business-container {
  width: 100%;
  height: 100vh;
  background: #f5f6f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  z-index: 0;
}
.bus-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  z-index: 5;
  max-width: 1920px;
  padding-top: 100px;
}
.bus-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.bus-top-left-txt01 {
  width: auto;
  height: 48px;
  font-size: 34px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 48px;
  text-align: left;
}

.bus-detail {
  width: 1199px;
  height: 78px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #898989;
  line-height: 39px;
  text-align: left;
}

.left-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 814px;
  height: 373px;
  z-index: -1;
  background: url('../../assets/bg_mask_02.png') no-repeat;
  background-size: 100%;
}
.right-img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 810px;
  height: 208px;
  background: url('../../assets/bg_mask_03.png') no-repeat;
  background-size: 100%;
  z-index: 0;
}
.bus-bottom {
  width: 1200px;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
.slide-img {
  width: 900px;
  height: 611px;
}
.swiper-container {
  width: 900px;
}
.swiper-slide {
  width: 900px;
  height: 611px;
}
.button {
  width: 150px;
  height: 611px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pre-img {
  width: 21px;
  height: 38px;
  background: url('../../assets/pre.png') no-repeat;
  background-size: 100% 100%;
}
.next-img {
  width: 21px;
  height: 38px;
  background: url('../../assets/next.png') no-repeat;
  background-size: 100% 100%;
}

img {
  width: 100%;
  height: 100%;
}

.fade-left-enter-active {
  animation: fade-left 0.35s linear;
}
.fade-left-leave-active {
  animation: fade-left 0.35s linear reverse;
}
@keyframes fade-left {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
