<template>
  <div class="container" @click="click">
    <Header @scroll="scrollEvent" />
    <div class="img-content">
        <img class="img" src="../../assets/banner_s.png">
        <div class="red-flg"></div>
        <div class="title">解决方案</div>
        <div class="sub-title">京孚信息人力资源解决方案，打造人力资源外包转型新模式。<br> 为客户提供快速、高质量的服务。</div>
    </div>
    <div class="industry" ref="industry">
      <div class="industry-title">行业领域</div>
      <div class="industry-detail">京孚信息为客户提供IT咨询，业务规划，行业解决方案，定制化开发与实施到系统维护服务的一站式全生命周期，价值链的IT解决方案服务，覆盖互联网，金融，通讯，教育，地产等众多行业。</div>
      <div class="industry-imgs-warp">
        <div class="imgs-item-1" @mouseenter="mouseenterEvent(1)" @mouseleave="mouseleaveEvent">
            <transition name="fade">
              <div v-if="hoverIndex == 1" class="hover-imgs-item-1">
                <div class="hover-title">互联网</div>
                <div class="hover-content">京孚信息致力于ICT与互联网企业业务的融合创新，引领互联网企业商业模式转化及运营模式的变革。为众多国内知名互联网企业提供多元化的全方位解决方案，是众多企业的核心信息技术服务提供商。</div>
              </div>
            </transition>
          </div>
        <div class="imgs-item-2" @mouseenter="mouseenterEvent(2)" @mouseleave="mouseleaveEvent">
          <transition name="fade">
            <div v-if="hoverIndex == 2" class="hover-imgs-item-2">
              <div class="hover-title">教育</div>
              <div class="hover-content">京孚信息是专业的教育行业IT解决方案和服务提供商，提供数字化转型服务。</div>
            </div>
          </transition> 
        </div>
        <div class="imgs-item-3" @mouseenter="mouseenterEvent(3)" @mouseleave="mouseleaveEvent">
          <transition name="fade">
            <div v-if="hoverIndex == 3" class="hover-imgs-item-3">
              <div class="hover-title">通讯设备</div>
              <div class="hover-content">京孚信息与众多通讯设备厂商有着密切的合作关系。测试从改进方法入手，提供产品测试计划、产品测试方案设计、产品测试用例开发、测试执行验证、各种外部认证的预测试服务。</div>
            </div>
          </transition>
        </div>
        <div class="imgs-item-4" @mouseenter="mouseenterEvent(4)" @mouseleave="mouseleaveEvent">
          <transition name="fade">
            <div v-if="hoverIndex == 4" class="hover-imgs-item-4">
              <div class="hover-title">高科技制造</div>
              <div class="hover-content">京孚信息在高科技领域拥有多元化的全方位解决方案，能够帮助客户从容应对市场变化并快速做出响应，推动客户产品及技术更快走向市场。</div>
            </div>
          </transition>
        </div>
        <div class="imgs-item-5" @mouseenter="mouseenterEvent(5)" @mouseleave="mouseleaveEvent">
          <transition name="fade">
            <div v-if="hoverIndex == 5" class="hover-imgs-item-5">
              <div class="hover-title">金融/银行</div>
              <div class="hover-content">京孚信息以深厚的行业知识为客户提供多样化的服务。除传统金融IT外，还将大数据及人工智能技术广泛应用于金融行业，帮助客户快速搭建大数据管理平台。实现数据的有序治理及高效利用，加强工作流程管理和商务智能，使客户能更好的进行风险管理，提高产品的性能和快速实现业务转型。</div>
            </div>
          </transition>
        </div>
        <div class="imgs-item-6" @mouseenter="mouseenterEvent(6)" @mouseleave="mouseleaveEvent">
          <transition name="fade">
            <div v-if="hoverIndex == 6" class="hover-imgs-item-6">
              <div class="hover-title">其他</div>
              <div class="hover-content">京孚信息涵盖更多行业，医疗科技、消费、交通运输… 期待与您合作！</div>
            </div>
          </transition>
        </div>
      </div>
      <div class="next">
         <div class="next-img" ></div>
      </div>
    </div>
    <div class="service" ref="service">
      <div class="industry-title">服务流程</div>
      <div class="service-img">
        <div class="case-img"></div>
      </div>
    </div>
    <div class="success-case" ref="successCase" @click="click">
      <div class="s-bg-1"></div>
      <div class="s-bg-2" ></div>
      <div class="industry-title">成功案例</div>
      <div class="case" @click="click">
        <div class="case-left" @click="click">
          <div :class="currentCase == index ? 'case-item-selected':''" class="case-item" v-for="(item,index) in leftCase" :key="index" @mouseenter="clickEvent(index)">
            <div :class="currentCase == index ? 'case-item-title-selected':''"  class="case-item-title">{{item.title}}</div>
            <div :class="currentCase == index ? 'case-item-sub-title-selected':''"  class="case-item-sub-title">{{item.subTitle}}</div>
          </div>
        </div>
        <div class="case-right">
          <img class="case-img" :src="imgUrl" >
        </div>
      </div>
    </div>
    <div class="question">
      <div class="qus-txt">
          <div class="qus-title">有疑问吗？</div>
          <div class="qus-content">我们期待您的咨询，<br> 请告诉我们您希望我们如何提供更多关于业务合作计划的信息。</div>
          <div class="qus-button" @click="contactMeEvent">联系我们</div>
      </div>
    </div>
    <Footer @scroll="scrollEvent"/>
  </div>
</template>

<script>
import Header from '../../components/Header'
import Footer from '../../components/Footer'

import case0 from '../../assets/success-case-0.png'
import case1 from '../../assets/success-case-1.png'
import case2 from '../../assets/success-case-2.png'
import case3 from '../../assets/success-case-3.png'


export default {
  components: {
    Header,Footer
  },
  data() {
    return {
      imgUrl: case0,
      currentCase: 0,
      hoverIndex: 0,
      leftCase:[
        {
          title: '东土科技',
          subTitle: '',
          img: '',
        }, {
          title: '启明星辰',
          subTitle: '',
          img: '',
        }, {
          title: '广联达',
          subTitle: '',
          img: '',
        }, {
          title: '千尧科技新能源',
          subTitle: '',
          img: '',
        }
      ]

    }
  },
  methods: {

    mouseenterEvent(index) {
      this.hoverIndex = index;
    },
    mouseleaveEvent() {
      this.hoverIndex = 0;
    },

    click() {

    },

    clickEvent(index) {
      const imgArr = [case0,case1,case2,case3]
      this.currentCase = index; 
      this.imgUrl = imgArr[index]
    },
    scrollEvent(query) {
      if (query && query.id) {
        this.$refs[query.id].scrollIntoView();
      } else {
        document.documentElement.scrollTop = 0;
      }
    },
    contactMeEvent() {
       let target = {
            name: '联系我们',
            url: '/partner',
            query: {
              id: 'contact'
            }
          }
        this.$router.push({
          path: target.url,
          query: target.query 
        })
    }
  },
  mounted() {
    this.scrollEvent(this.$route.query)
  },
}
</script>

<style scoped>
@font-face {
  font-family: SourceHanSansCN;
  src: url('../../assets/font/SourceHanSansCN-Heavy.ttf') format("truetype");
}

div {
  box-sizing: border-box;
}

.container {
  width: 100%;
  height: 100%;
}

.img-content {
  width: 100%;
  height: 665px;
  position: relative;
}
.red-flg {
  position:absolute;
  width: 117px;
  height: 46px;
  background: #C30F23;
  border-radius: 4px;
  left: 362px;
  top: 283px;
}
.title {
  font-size: 46px;
  font-family: SourceHanSansCN;
  font-weight: 800;
  color: #FFFFFF;
  line-height: 58px;
  position:absolute;
  left: 418px;
  top: 290px;

}
.img {
  position:absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.sub-title {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 40px;
  position:absolute;
  left: 418px;
  top: 359px;
  text-align: left;
}
.industry {
  width: 100%;
  margin-top: 100px;
  padding: 0px 362px;
  box-sizing: border-box;
}

.industry-title {
  font-size: 34px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 48px;
  text-align: left;
  position: relative;
  z-index: 9;
}
.industry-detail {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #898989;
  line-height: 34px;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 50px;
}

.industry-imgs-warp {
  width: 100%;
  height: 579px;
  position: relative;
  text-align: left;
  overflow: hidden;
}
.imgs-item-1 {
  width: 400px;
  height: 289px;
  position:absolute;
  left: 0px;
  top: 0px;
  background: url('../../assets/imgs-item-1.png') no-repeat;
  background-size: 100%;
  overflow: hidden;
}
.imgs-item-2 {
  width: 400px;
  height: 173px;
  position:absolute;
  left: 400px;
  top: 0px;
  background: url('../../assets/imgs-item-2.png') no-repeat;
  background-size: 100%;
  overflow: hidden;
}
.imgs-item-3 {
  width: 400px;
  height: 416px;
  position:absolute;
  left: 799px;
  top: 0px;
  background: url('../../assets/imgs-item-3.png') no-repeat;
  background-size: 100%;
  overflow: hidden;
}
.imgs-item-4 {
  width: 400px;
  height: 290px;
  position:absolute;
  left: 0px;
  top: 287px;
  background: url('../../assets/imgs-item-4.png') no-repeat;
  background-size: 100%;
  overflow: hidden;
}
.imgs-item-5 {
  width: 399px;
  height: 407px;
  position:absolute;
  left: 400px;
  top: 171px;
  background: url('../../assets/imgs-item-5.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
.imgs-item-6 {
  width: 400px;
  height: 164px;
  position:absolute;
  left: 799px;
  top: 414px;
  background: url('../../assets/imgs-item-6.png') no-repeat;
  background-size: 100%;
  overflow: hidden;
}

.next {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 56px;
}

.service {
  width: 100%;
  padding: 0px 362px;
  box-sizing: border-box;
}
.service-img {
  width: 1233px;
  height: 441px;
  background: url('../../assets/service-process.png') no-repeat;
  background-size: 100%;
}
.success-case {
  margin-top: 114px;
  width: 1920px;
  height: 975px;
  background: #F5F6F7;
  padding: 163px 362px 0px 362px;
  box-sizing: border-box;
  position: relative;
  z-index: 10;
}
.s-bg-1 {
  position:absolute;
  left: 0;
  top: 0;
  width: 759px;
  height: 256px;
  background: url('../../assets/solution-bg-1.png') no-repeat;
  background-size: 100%;
  z-index: 11;
}
.s-bg-2 {
  position:absolute;
  right: 0;
  bottom: 0;
  width: 759px;
  height: 256px;
  background: url('../../assets/solution-bg-2.png') no-repeat;
  background-size: 100%;
  z-index: 11;
}
.case {
  margin-top: 26px;
  width: 100%;
  height: 584px;
  background: #FFFFFF;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 99;
}
.case-left {
  width: 385px;
  height: 100%;
  z-index: 999;
}
.case-right {
  width: 811px;
  height: 100%;
}
.case-item {
  width: 385px;
  height: 146px;
  background: #FFFFFF;
  border-radius: 4px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 55px;
  box-sizing: border-box;
  z-index: 9999;
}
.case-item-selected {
  background: #F5A100;
}
.case-item-title {
  width: 100%;
  text-align: left;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 33px;
}
.case-item-title-selected {
  color: #fff;
}
.case-item-sub-title {
  width: 100%;
  text-align: left;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #898989;
  line-height: 40px;
}
.case-item-sub-title-selected {
  color: #fff;
}
.case-img {
  width: 100%;
  height: 100%;
}
.question {
  position: relative;
  width: 100%;
  height: 441px;
  padding: 116px 362px 101px 362px;
  box-sizing: border-box;
  background: url('../../assets/case-question.png') no-repeat;
  background-size: 100% 100%;
  margin: 0;
  z-index: 0;
}
.q-img {
  position:absolute;
  left: 0;
  top: 0;
  
}
.qus-title {
  font-size: 34px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 48px;
}
.qus-content {
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 32px;
  margin-top: 7px;
}
.qus-button {
  width: 193px;
  height: 68px;
  background: #FFFFFF;
  border-radius: 8px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 68px;
  margin-top: 32px;
}
.qus-button:hover {
  color: #000000;
}
.qus-txt {
  z-index: 999;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hover-imgs-item-1 {
  width: 100%;
  height: 100%;
  z-index: 9;
  background: url('../../assets/hover-imgs-item-1.png') no-repeat;
  background-size: 100%;
  padding: 30px 35px;
}
.hover-imgs-item-2 {
  width: 100%;
  height: 100%;
  z-index: 9;
  background: url('../../assets/hover-imgs-item-2.png') no-repeat;
  background-size: 100%;
  padding: 30px 35px;
}
.hover-imgs-item-3 {
  width: 100%;
  height: 100%;
  z-index: 9;
  background: url('../../assets/hover-imgs-item-3.png') no-repeat;
  background-size: 100%;
  padding: 30px 35px;
}
.hover-imgs-item-4 {
  width: 100%;
  height: 100%;
  z-index: 9;
  background: url('../../assets/hover-imgs-item-4.png') no-repeat;
  background-size: 100%;
  padding: 30px 35px;
}
.hover-imgs-item-5 {
  width: 100%;
  height: 100%;
  z-index: 9;
  background: url('../../assets/hover-imgs-item-5.png') no-repeat;
  background-size: 100%;
  padding: 30px 35px;
}
.hover-imgs-item-6 {
  width: 100%;
  height: 100%;
  z-index: 9;
  background: url('../../assets/hover-imgs-item-6.png') no-repeat;
  background-size: 100%;
  padding: 30px 35px;
}
.hover-title {
  font-size: 28px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 40px;
  z-index: 11;
}
.hover-content {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 34px;
  z-index: 11;
  text-align: justify;
}
.hover-img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}
.next-img {
  width: 38px;
  height: 20px;
  background: url('../../assets/next-page.png') no-repeat;
  background-size: 100%;
}

.slide-fade-enter-active {
  animation:fade 0.25s
}
.slide-fade-leave-active {
  animation: fade 0.2s reverse;
}
@keyframes fade {
0% {
    transform: translateY(100%);
  }
  
  100% {
    transform: translateY(0);
  }
}

</style>