<template>
  <div class="container">
    <Header @scroll="scrollEvent" />
    <div class="img-content">
      <img class="img" src="../../assets/banner-join.png">
      <div class="red-flg"></div>
      <div class="title">公司与个人的利益是不可分割的</div>
      <div class="sub-title">在京孚 每个人都有充分机会去解决最重要的问题<br>如果你希望肩负这种责任那么立即加入京孚<br>这里的资源可以帮助你找到自己的方向</div>
    </div>
    <div class="solicitude" ref="solicitude">
      <div class="so-title">员工关怀</div>
      <div class="so-sub-title">京孚信息始终坚持“以人为本”的企业文化，尊重员工个性，努力为员工提供更好的工作环境、职业发展和生活质量。工作，有所突破即成就；生活，简单轻松即快乐。让每个员工工作充实，生活快乐，无论身在何处，都能感受到公司的关怀！</div>
      <div class="so-imgs">
        <div class="so-imgs-item" @mouseenter="mouseenterEvent(1)" @mouseleave="mouseleaveEvent(1)">
            <img src="../../assets/join-us-1.png" />
            <transition name="fade">
              <div v-if="item_hover_1" class="hover-so-item">
                <div class="hover-so-item-txt">成长</div>
                <div class="hover-so-item-bg"></div>
              </div>
            </transition>
        </div>
        <div class="so-imgs-item" @mouseenter="mouseenterEvent(2)" @mouseleave="mouseleaveEvent(2)">
          <img  src="../../assets/join-us-2.png" />
          <transition name="fade">
            <div v-if="item_hover_2" class="hover-so-item">
                <div class="hover-so-item-txt">互助</div>
                <div class="hover-so-item-bg"></div>
            </div>
           </transition>
        </div>
        <div class="so-imgs-item" @mouseenter="mouseenterEvent(3)" @mouseleave="mouseleaveEvent(3)">
          <img src="../../assets/join-us-3.png" />
          <transition name="fade">
            <div v-if="item_hover_3" class="hover-so-item">
              <div class="hover-so-item-txt">关怀</div>
              <div class="hover-so-item-bg"></div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="section " ref="">
      <div class="sec-left">
        <div class="sec-title">补充商业保险</div>
        <div class="sec-sub-title">每年公司都为每个员工提供一份安心保障：包括团体意外险+定期寿险+重大疾病险+女性生育险+补充医疗保险，包含补充医疗保险100%赔付。</div>
        <div class="sec-left-img">
          <img src="../../assets/join-us-tijian.png" />
        </div>
      </div>
      <div class="sec-right">
         <div class="sec-right-img">
          <img src="../../assets/join-us-guanhuai.png" />
        </div>
         <div class="sec-r-title">年度体检</div>
         <div class="sec-r-sub-title">关注员工健康，每年让员工进行一次体检。</div>
      </div>
    </div>
    <div class="interview" ref="interview">
      <div class="in-left">
        <div class="in-title">面试过程</div>
        <div class="in-sub-title" >在我们的所有团队中，面试均从一到两个电话面试开始。这些电话面试通常持续二十五至四十五分钟，重点要了解你的以往经历、领域知识和对京孚信息的个人兴趣。我们还会给你留出时间向我们提问。工程师面试往往包括数据结构、算法和软件工程等技术问题。电话面试如果顺利的话，就可以前往北京首享科技大厦或者工作场地与我们的团队见面并进行现场面试。保持京孚文化对我们至关重要，所以我们希望谨慎地确保双向选择的彼此适合。我们希望了解真正的你，并真诚以对。</div>
        <img class="more-red" src="../../assets/more-red.png" />
      </div>
      <div class="in-right">
        <div class="in-item" v-for="(item,index) in interList" :key="index" >
          <div class="in-item-title">
            <div class="in-dot"></div>
            <div class="in-r-title">{{item.title}}</div> 
          </div>
          <div class="in-item-detail">{{item.detail}}</div>
        </div>
      </div>
    </div>
    <div class="job" ref="hot_job">
      <img class="s-bg-1" src="../../assets/solution-bg-1.png" />
      <div class="job-content">
        <div class="job-title">热招职位</div>
        <div class="job-phone">我们欢迎各类优秀人才加入，敬请投递或推荐简历至：hr@jingfuchina.com <br>招聘无淡季，京孚欢迎你！</div>
        <div class="job-tips">温馨提示：应聘者的简历信息，将被保留在北京京孚信息技术有限公司的人才库中。在处理个人信息过程中，北京京孚信息技术有限公司承诺尊重应聘者的个人隐私，遵守集团个人信息保护政策、网络安全法、以及信息安全技术个人信息安全规范，不将应聘者信息用作招聘以外其他用途。</div>
        <div class="job-list">
          <div :class="current_job == index ? 'job-list-item-selected':''" class="job-list-item" v-for="(item,index) in jobList" :key="index" :style="index%5 == 4 ? 'margin-right:0px':''" @click="clickEvent(item,index)" >
            {{item.name}}
          </div>
        </div>
        <div class="job-line"></div>
        <div class="job-intro">
          <div class="job-intro-title">{{job_title}}</div>
          <div class="job-intro-item">岗位描述</div>
          <div class="job-content-item" v-html="job_desc"></div>
          <div class="job-intro-item">任职要求</div>
          <div class="job-content-item" v-html="job_require"></div>
        </div>
      </div>

    </div>

    <Footer @scroll="scrollEvent" />
  </div>
</template>

<script>
import Header from '../../components/Header'
import Footer from '../../components/Footer'
export default {
  components: {
    Header,Footer
  },

  data() {
    return {
      job_title:'',
      job_count: '',
      job_desc: '',
      job_require: '',
      current_job: 0,
      item_hover_1: false,
      item_hover_2: false,
      item_hover_3: false,
      selectedItem: {},
      interList: [
          {
            title:'电话邀约',
            detail: '筛选出来的简历，要进行电话邀约，在电话里面先做简单的情况了解，确定求职意向和时间来参加公司面试'
          },
           {
            title:'发送面试邀请',
            detail: ''
          },
           {
            title:'部门面试',
            detail: '了解工作经历及通用技能以及个人职业规划，技术岗位需要笔试'
          },
           {
            title:'人力面试',
            detail: '确认职能匹配后是人力面试，沟通薪酬及公司保险、福利'
          },
          {
            title:'确定录入',
            detail: '发送正式的录用通知书，进行后续的录用工作，签入职协议'
          }
      ],
      jobList:[
        {
          name: 'Java高级工程师',
          desc: '1.根据需求及设计完成机构服务平台中后开发和测试工作；<br />2.微服务架构应用技术攻关及解决技术问题；<br>3.协助完成机构服务平台产品后台服务设计；',
          require: '1.全日制大学本科或以上学历，计算机、软件工程或相关专业，5年以上Java应用开发经验；<br />2.熟悉Java 后台应用开发、具备一定的架构设计能力和文档能力；<br />3.熟悉Spring Cloud微服务架构，熟悉Spring MVC、Structs等开发框架;<br /> 4.对Java多线程编程、socket、对象池等编程技术开发有一定的经验.掌握细节及原理；<br /> 5.熟悉UNIX/Linux操作系统，具备UNIX/Linux环境下系统开发经验优先；<br />6.对OOP、AOP有深刻的理解、掌握面向对象软件分析、设计方法，熟悉设计模式；<br />7.掌握常用的设计模式.能独立完成功能模块的系分和开发.能够考虑程序功能.性能及扩展性；'
        },
        {
          name: 'Java中级工程师',
          desc: '1.根据需求及设计完成机构服务平台中后开发和测试工作；<br />2.微服务架构应用技术攻关及解决技术问题；<br />3.协助完成机构服务平台产品后台服务设计；',
          require: '1.全日制大学本科或以上学历，计算机、软件工程或相关专业，3-5年Java应用开发经验；<br />2.熟悉Java 后台应用开发、具备一定的架构设计能力和文档能力；<br />3.熟悉Spring Cloud微服务架构，熟悉Spring MVC、Structs等开发框架;<br />4.对Java多线程编程、socket、对象池等编程技术开发有一定的经验.掌握细节及原理；<br /> 5.熟悉UNIX/Linux操作系统，具备UNIX/Linux环境下系统开发经验优先；<br /> 6.对OOP、AOP有深刻的理解、掌握面向对象软件分析、设计方法，熟悉设计模式；<br /> 7.掌握常用的设计模式.能独立完成功能模块的系分和开发.能够考虑程序功能.性能及扩展性；'
        },
        {
          name: '前端工程师',
          desc: '1. 根据UI设计师提供的设计图，实现一流的Web界面，优化代码并保持在各浏览器下良好的兼容性；<br />2. Web前端表现层及与后端交互的架构设计和开发；<br />3. JavaScript程序模块开发，通用类库、框架编写；<br />4. 配合后台开发人员实现产品界面和功能；<br />5. 对完成的页面进行维护和对网站前端性能做相应的优化。',
          require: '1. 统招本科学历，3年以上互联网前端开发经验，优 秀的Javascript基本功，熟悉ECMAScript语言标准；<br />2. 熟悉vue框架；<br />3. 了解一定的后端业务，熟悉一些后端语言更佳，如 java,python；<br />4. 参与过数据可视化项目，熟悉Echarts、Hicharts等组件；<br />5. 优秀的沟通理解能力、团队合作能力，能够与多部门良好协 作，推动项目落地；<br />'
        },
         {
          name: '大数据开发工程师',
          desc: '1．理解客户需求，和建模人员及客户沟通并制定ETL开发规范以及开发实施；<br />2．使用pyspark在微软云环境开发ETL；<br />3．大数据开发性能调优以及监控；<br />4．使用airflow调度pyspark job。',
          require: '1.精通Spark streaming、spark core、spark sql，有PySpark开发经验优先；<br />2. 精通python语言；<br />3.具备良好团队合作精神，较强的沟通交流能力；<br />4.具备良好的文档撰写能力；<br />5.3年以上大数据开发经验；<br />6.熟悉大数据生态组件特性，有大规模集群架构、开发、运维及调优经验。<br />加分项：<br />1使用过ETL开发工具Talend，kettle，SSIS，Informatica等；<br />2.英文读写正常。<br />'
        },
         {
          name: '测试工程师',
          desc: '1. 根据需求文档提炼测试功能点，编写和执行测试案例，保证测试案例合理有效；<br />2. 对从功能，性能，数据准确性，UI等方便对Power BI报表进行测试； <br />3. 编写测试报告等文档； <br/>4.协调开发人员完成报表的修改；<br />5.强烈的责任心和敬业精神，良好的沟通能力和积极主动精神，良好的团队合作精神，完成Bug的创建，分配，跟踪，关闭；<br />6.定期整理测试中出现的问题，向团队汇报，协助PM提高交付质量。',
          require: '1. 2年以上数据报表测试与分析经验， Power BI 报表测试经验优先；<br />2. 需要有较强的逻辑分析能力、熟悉报表的分析与测试方法；<br />3. 较好的沟通与协调处理能力，做事认真有责任心和条理性；<br />4. 良好的英文读写能力，可以书写英文测试文档；<br />5. 熟悉快消行业业务者优先考虑。'
        },
        {
          name: '数据分析师',
          desc: '1.协助销售团队和数据科学团队完成：<br />i）主数据创建和清理；<br />ii）运行简单的SQL查询；<br />iii）在分析前准备/清理数据；<br />2.定期和按需数据分析和可视化，例如趋势分析，用户分析；<br />3.技能要求：熟练使用Excel，中级SQL知识。其他技术能力（Python，Linux，可视化工具如Tableau）会加分，但不是必须的。<br />',
          require: '1.工程或技术学士学位；<br />2.2年以上FMCG行业经验；<br />3.有数据ETL和建模经验者优先；<br />4.自我激励、快速学习和团队协作；<br />5.大学英语四级，英语六级为佳。'
        },
        {
          name: '.NET开发工程师',
          desc: '1、负责现有.NET系统的日常技术运维工作，负责日常系统技术问题的解决；<br />2、负责和参与HR系统的新需求的收集、分析、概要设计工作；<br />3、协助项目经理完成项目的调研、选商、协调测试、实施和上线工作。',
          require: '1、本科及以上学历 计算机、数学、信息系统等相关专业，4年左右工作经验；<br />2、有HR相关系统经验优先；<br />3、熟悉 C#语言，.NET B/S 项目工作经验4年或以上，项目经验丰富；<br />4、熟悉 HTML、Javascript、CSS、jQuery；<br />5、熟练使用.NET MVC、ADO.NET；<br />6、熟悉 EntityFramework ，Code First & DB First都需要有经验；<br />7、熟悉模块化开发思路 & 熟悉依赖注入；<br />8、熟悉Sql Server 数据库,熟悉SQL语句，熟练编写存储过程、View；<br />9、熟悉SSO认证方式；<br />10、了解熟悉Java、BI等相关技术优先；<br />11、沟通能力良好；<br />12、具有良好的团队合作精神和OwnerShip。<br />13、工作态度端正、责任心强、沟通能力强、能承受较强的工作压力。'
        },
         {
          name: '架构师',
          desc: '1、全面负责公司云平台架构；<br />2、分析业务需求，验证可行性，评估技术风险，估算开发成本，支持管理决策；<br />3、根据产品需求，提炼非功能性架构需求，确定软件架构；<br />4、培训技术团队，审查技术团队的设计方案，并驱动技术团队实现软件架构；<br />5、参与核心模块代码的编写。',
          require: '1、熟悉Spring cloud和Service mesh微服务框架，有实际环境搭建和运维经验；<br />2、熟悉公有云和私有云PaaS环境和相关中间件，有实际环境搭建和运维经验；<br />3、熟悉零售行业销售的流程和管理，比如门店管理、用户管理、渠道管理、市场营销等；<br />4、或者熟悉零售行业供应量的业务流程，比如TMS、WMS、OMS等；<br />5、熟悉devop流程，有实际环境搭建和运维经验；<br />6、熟悉k8s+docker，有实际环境搭建和运维经验；<br />7、熟悉大数据和AI，有实际环境搭建、运维、研发能力优先考虑；<br />8、熟悉IaaS，有实际环境搭建、运维、研发能力优先考虑；<br />9、英语读写能力；<br />10、工作态度认真和积极。'
        },
        {
          name: '自动化测试工程师',
          desc: '1、根据产品规范编写测试计划，设计测试数据和测试用例；<br />2、负责产品的多平台测试工作，不限于操作系统及硬件，配合其他人员及合作伙伴找出问题；<br />3、编写和提交测试报告，保证测试进度按计划完成；<br />4、负责多个系统及多个软件性能测试；<br />5、针对不同系统和软件能提出测试方案 。',
          require: '1．计算机及相关专业，本科及以上学历，4年及以上白盒测试工作经验 熟悉测试体系、测试理论、测试流程、测试用例设计方法；<br />2. 熟悉常用接口测试工具如LoadRunner Jmeter、postman等；<br />3. 熟悉功能测试、性能测试，黑盒测试方法 熟悉bug跟踪管理工具；<br />4. 有较强的逻辑分析能力和学习能力，工作责任心强，细致有耐心,高度的责任心和自我驱动能力，团队合作意识和沟通能力；<br />5. 掌握软件开发相关技术和知识，掌握自动化测试。'
        },
        {
          name: 'C++开发工程师',
          desc: '1、负责使用最新的软件开发技术和基础设施，在快速、敏捷的开发环境中定义、开发和迭代软件；<br />2、将需求/愿景转化为用户情景的优先列表，并按照要求的时间表和质量标准交付；<br />3、与其他业务部门进行跨职能合作，协调各项活动和可交付成果；<br />4、应用SDLC的原则和方法，比如敏捷、CI、软件和产品安全、可伸缩性、文档实践、重构和测试技术；<br />5、使用为项目选择的技术编写符合标准并提供所需功能的代码。',
          require: '1.计算机软件相关理工类及生物医学工程专业，本科及以上学历，3年以上工作经验；<br />2.熟练掌握C++编程语言和面向对象程序分析与设计；<br />3.良好的英文读写能力；<br />4.有Linux程序开发和调试经验者优先；<br />5.同时掌握多种编程语言者优先；<br />6.有医学项目背景者优先；<br />7.责任心强，具备良好的沟通能力和团队精神。'
        },
         {
          name: 'ETL开发工程师',
          desc: '1. 负责ETL过程的设计与实现数据仓库的数据采集，清洗和逻辑处理；<br />2. 协助模型设计师完成数据源分析工作；<br />3. 用SSIS 实现ETL的开发、测试, workflow job 的调度设计和实现；<br />4. 根据需求，进行数据仓库方面的技术支持。',
          require: '1. 本科及以上学历，计算机相关专业毕业；<br />2. 3年以上SQL SERVER ，SSIS ,ETL开发经验；<br />3. 掌握存储过程的编写，SQL语句的性能调优；<br />4. 有数据模型设计的能力；<br />5. 具备较好的表达能力和沟通能力；<br />6. 有大数据技术者优先（Spark, Python, Databricks ）。'
        },
         {
          name: '信息安全工程师',
          desc: '1. 积极参与基础设施架构设计和建设，根据业务需求提供安全解决方案；<br />2. 负责网络安全工具/设备的部署和运行，包括安全策略的设计、部署、维护和优化；<br />3. 负责安全事件管理，包括监控安全事件、快速响应安全攻击/事件、紧急修复和采取纠正和预防措施；<br />4. 及时了解当前和新出现的安全威胁，评估对系统的潜在风险和影响，并设计安全架构以减轻这些威胁；<br />5. 在设计和构建阶段为所有基于云的应用程序和本地应用程序（必要时）提供安全建议，以确保安全的开发和部署；<br />6. 参与安全政策和程序的制定以及安保项目的实施和管理；<br />7. 支持内部和外部安全审计。',
          require: '1.计算机科学、信息安全或相关领域（如IT审计、企业风险管理等）学士学位；<br />2.精通网络安全工具的基础知识和解决方案，如防火墙、IPS、防病毒、漏洞扫描、日志审核、用户认证；<br />3.了解主要的安全技术和安全产品，以及法规遵从性和信息安全管理框架（如ISO27001、MLPS2.0、CCM等）；<br />4.2+安全运营经验，包括事件处理和安全工具设置；1年以上公共云安全运营经验（如AWS、阿里云、腾讯云等）；<br />5.熟悉已知的网络或系统漏洞，熟悉安全事件分析和处理流程；<br />6.至少熟悉一种操作系统：Linux、Unix、Windows。'
        },
        {
          name: '软件测试工程师',
          desc: '1、根据产品规范编写测试计划，设计测试数据和测试用例；<br />2、负责产品的多平台测试工作，不限于操作系统及硬件，配合其他人员及合作伙伴找出问题；<br />3、编写和提交测试报告，保证测试进度按计划完成；<br />4、负责多个系统及多个软件性能测试；<br />5、针对不同系统和软件能提出测试方案 。',
          require: '1．计算机及相关专业，本科及以上学历，4年及以上白盒测试工作经验 熟悉测试体系、测试理论、测试流程、测试用例设计方法；<br />2. 熟悉常用接口测试工具如LoadRunner Jmeter、postman等；<br />3. 熟悉功能测试、性能测试，黑盒测试方法 熟悉bug跟踪管理工具；<br />4. 有较强的逻辑分析能力和学习能力，工作责任心强，细致有耐心,高度的责任心和自我驱动能力，团队合作意识和沟通能力；<br />5. 掌握软件开发相关技术和知识，掌握自动化测试。'
        },
         {
          name: 'REACT 前端开发',
          desc: '1、负责网站前端开发，实现产品的页面交互及功能实现;<br />2、与程序开发人员紧密合作，制作前端及后端程序接口标准;<br />3、完成产品的设计、开发、测试、修改bug等工作，包括业务需求的沟通，功能模块详细设计，业务功能实现和功能变更维护;<br />4、持续的优化前端体验和页面响应速度，优化代码并保持良好兼容性，提升web界面的友好和易用。',
          require: '1. 本科及以上学历计算机相关专业，接受优秀的应届生（已经有毕业证书的）；<br />2. 精通HTML和CSS 及JavaScript面向对象编程方法；<br />3. 精通React、Redux、Nextjs、hooks，熟悉jquery，理解MVC、MVVM模式；<br />4. 熟悉前端模块化开发，理解前端工程自动化构建，熟悉webpack等工具；<br />5. 熟悉各种常见跨浏览器、跨设备问题，深刻理解Web标准；<br />6. 熟练使用TypeScript进行React组件开发，理解React渲染机制，组件化思想；<br />7. 熟练使用mock.js进行数据的模拟测试；<br />8. 熟练掌握dva、mobx等状态管理工具；<br />9. 熟练使用antd、ant mobile等react组件库；<br />10. 熟悉常用的包管理器（vower、npm）；<br />11. 重视团队协作，项目经验丰富，对前端相关技术有前瞻性的视野；<br />12. 对新技术领域、新兴Web标准和Web发展趋势有良好洞察力和关注度 。'
        },
         {
          name: '高级UI 设计师',
          desc: '1. 负责网页及移动端的用户体验及UI设计，参与制定项目UI设计的详细设计规范，编写思路并且整理设计规范文档；<br />2. 分析业务需求，梳理用户痛点，协助产品经理完成界面的信息架构，流程设计和原型设计，提高产品的易用性；<br />3. 根据产品原型完成相关的界面与视觉设计；<br />4. 对现有产品的可用性测试和评估提出改进方案，持续优化产品的用户体验与视觉设计；<br />5. 与软件开发团队沟通，帮助开发人员实现设计方案；<br />6. 线上线下宣传物料的内容设计制作。',
          require: '1. 3年以上的相关设计工作经验；<br />2. 对用户体验、交互操作流程及用户需求有一定了解，有互联网从业经验的优先；<br />3. 精通Photoshop，Illustrator等设计相关软件；<br />4. 熟悉HTML代码、DIV和CSS规范、能运用JavaScript前端框架实现高保真的Web交互原型；<br />5. 良好的团队合作精神、较强的沟通能力，对待工作积极主动有责任心。<br />※面试时需带好设计作品或上线产品（近期为佳）。'
        },
         {
          name: '质量管理工程师',
          desc: '负责显示系统硬件（或者软件）的整个设计开发过程（生命周期）的活动的符合性，主要描述如下：<br />1、编制硬件（或者软件）设计开发活动的设计保证文件，如程序文件，流程文件，检查单，项目计划文件等；<br />2、监管硬件设（或者软件）计开发活动对内外部要求的符合性；<br />3、评审硬件（或者软件）设计开发活动相关的PR/CR，参与对应的CCB工作，转阶段评审等；<br />4、目击硬件（或者软件）设计开发活动中相关试验；<br />5、支持客户开展的硬件（或者软件）设计开发先关的评审，并监督供应商相关的硬件设计开发活动。',
          require: '1.有质量管理的工作经历，3年以上（质量体系管理，质量保证，质量控制等）；<br />2.英文读写良好；<br />3.良好的沟通能力。'
        },
         {
          name: 'FPGA验证',
          desc: '1.组织参与FPGA需求、架构、代码评审；<br />2.负责FPGA仿真平台搭建、测试用例编写，功能仿真、时序仿真；<br />3.负责FPGA代码覆盖率分析；<br />4.复责满足DO-254 要求的静态时序分析；<br />5.负责FPGA实物级测试用例编写及执行；<br />6.负责满足DO-254 要求的FPGA验证文档编制和评审；<br />7.支持满足DO-254 要求的FPGA验证的流程处理；<br />8.支持SOI适航相关评审；',
          require: '1.有FPGA中大型项目验证经验；<br />2.熟悉FPGA验证流程；<br />3.熟悉verilog或System Verilog;<br />4.熟练使用Questasim或者IES仿真工具；<br />5.具有良好的沟通能力和团队合作精神；<br />6.具有视频处理逻辑或者网络验证经验者优先考虑；'
        },
         {
          name: '安卓开发工程师',
          desc: '1、融入团队一起承担项目的迭代开发；<br />2、负责安卓端应用开发，能够实现进行较深入的优化和模块化提取；<br />3、负责医疗项目扩展，并实现示例程序的开发，完成相关的开发总结及文档编写工作；<br />4、负责Android开发总体设计，核心功能模块开发，保证产品的质量和体验；<br />5、负责编写相关开发文档、技术资料；<br />6、与项目相关人员配合共同完成应用软件的开发设计工作。',
          require: '1、全日制大专以上学历；<br />2、掌握Android/Java各知识点，有线上移动产品并有持续优化经验者优先；<br />3、热爱移动开发，熟悉优秀第三方开源工具和库；<br />4、思路清晰，自我驱动，团队意识强，积极的工作态度、有一定的抗压能力，善于沟通和团队合作；<br />5、3年以上移动互联网开发经验，有2年以上安卓方向的开发经验；<br />6、学习能力强，具有极客精神，热爱技术，勇于探索最新的技术趋势；<br />7、有扎实的JAVA开发优先考虑；<br />8、具有较强的逻辑分析和解决问题的能力。能够独立完成开发总结和文档编写。'
        },
         {
          name: '后端ARCGIS开发工程师',
          desc: '1、参与项目的需求分析，负责GIS系统技术选型、架构设计、功能模块设计及开发；<br />2、负责相关项目GIS部分的开发工作，承担模块、组件的功能应用设计、代码开发、单元测试；<br />3、负责项目GIS相关技术方案、操作手册等技术文档撰写；<br />4、负责精细化地图处理、地图服务发布、三维地图技术研究与应用；<br />5、GIS相关服务日常维护、对外技术交流、新技术引进。',
          require: '技术栈：PostgreSQL、GeoServer、ArcMap；<br />1、熟悉ARCMap基本操作、ARCServer的地图发布；<br />2、熟悉AutoCAD的基本操作，掌握数据整合加工（数据格式转换、坐标转换等）；<br />3、熟悉一种空间数据库，如：PostgreSQL；<br />4、三维GIS开发者优先。'
        },
         {
          name: '高级运维工程师',
          desc: '1.系统运维(版本管理、自动发布、环境调优)；<br />2.问题调查(JAVA程序、DB2数据库)；',
          require: '1 .本科或硕士以上学位，计算机或者相关专业或者具有相关的行业经验并5年以上软件开发经验；<br />2. 具备开发能力（java、DB2）；<br />3. 要求软件环境运维经验，熟练掌握jenkins、华为云；<br />4 .如有听云、新云、蓝鲸智云的优先考虑；<br />5 .具备强烈的进取心、责任心、工作认真、独立工作能力强，善于发现并解决疑难技术问题；<br />6. 具备良好主动的沟通协调能力，良好的团队精神。'
        },
        {
          name: 'IOS开发工程师',
          desc: '1. 负责开发iOS应用软件开发、调试和维护；<br />2. 参与需求、概设评审及业务方案的设计并能给出自己的建议；<br />3. 协助测试工程师，完成测试工作；<br />4. 负责单元测试。',
          require: '1. 全日制本科以上学历，3年以上IOS开发经验；<br />2. 熟练使用Swift语言，较强的数据结构和算法知识；<br />3. 熟悉IOS相关界面、网络、数据库、多线程、异步调用、并发处理等开发技术；<br />4. 熟练使用CocoaPods，并熟悉HandyJSON、Moya、SnapKit等常用第三方库；<br />5. 熟练使用Git代码版本管理工具；<br />6. 有符合规范的良好编程风格，能够保证代码质量；<br />7. 工作认真负责，态度端正，乐于学习，对技术有一定追求；<br />8. 有单元测试相关经验优先。'
        },
        {
          name: '大客户销售',
          desc: '1、负责软件服务外包业务大客户开发、维护，执行公司销售计划，完成所负责大客户的销售任务；<br />2、能独立开拓新客户，构建行业客户网络，建立良好合作关系；<br />3、了解客户需求，制定个人销售计划，进行商务谈判，合同签署，客户维护和回款，；<br />4、对潜在客户进行定期跟踪，挖掘需求。',
          require: '1、计算机或营销相关专业，本科以上学历；<br />2、善于沟通，富有责任心，工作积极主动，执行力强，较强的团队协作和独立开展工作能力；<br />3、能承受工作压力，有企业客户销售经验，最好是大客户销售或参与过投标客户经验优先；<br />4、IT外包业务销售经验优先'
        },
        {
          name: '招聘专员',
          desc: '1、根据业务战略发展和公司编制预算，满足业务编制填充；<br />2、积极和业务部门沟通，了解业务的招聘需求并为业务部门提供有效的解决方案，可以自主创新招聘方式者为佳；<br />3、不断了解外部竞争动态，做好人才储备方案和定期Mapping；<br />4、根据安排，参与到校招等项目型工作。',
          require: '1、 具有3年以上招聘相关工作经验；<br />2、 有研发招聘经验者优先；<br />3、 有人力项目运营经验者优先；<br />4、 自我驱动能力强，结果导向，重视团队合作；<br />5、 优秀的逻辑和沟通能力，资源整合和调配能力，可以独立开展招聘工作；<br />6、 灵活懂变通，能适应行业的快速发展和变化，有很强的自驱力及抗压能力。'
        },
      ] 
    }
  },

  mounted() {
    this.scrollEvent(this.$route.query)
    
    let item = this.jobList[0];
    this.job_title = item.name;
    this.job_count = item.count;
    this.job_desc = item.desc;
    this.job_require = item.require;
  },
  methods: {
    mouseenterEvent(index) {
      if (index == 1) {
        this.item_hover_1 = true
      }
      if (index == 2) {
        this.item_hover_2 = true
      }
       if (index == 3) {
        this.item_hover_3 = true
      }
    },
    mouseleaveEvent(index) {
      if (index == 1) {
        this.item_hover_1 = false
      }
      if (index == 2) {
        this.item_hover_2 = false
      }
       if (index == 3) {
        this.item_hover_3 = false
      }
    },

    scrollEvent(query) {
      if (query && query.id) {
        this.$refs[query.id].scrollIntoView();
      } else {
        document.documentElement.scrollTop = 0;
      }
    },

    clickEvent(item,index) {
      this.current_job = index
      this.job_title = item.name;
      this.job_count = item.count;
      this.job_desc = item.desc;
      this.job_require = item.require;
    }
  },
}
</script>

<style scoped>
@font-face {
  font-family: SourceHanSansCN;
  src: url('../../assets/font/SourceHanSansCN-Heavy.ttf') format("truetype");
}

div {
  box-sizing: border-box;
}

.img-content {
  width: 100%;
  height: 665px;
  position: relative;
}
.red-flg {
  position:absolute;
  width: 117px;
  height: 46px;
  background: #C30F23;
  border-radius: 4px;
  left: 362px;
  top: 283px;
}
.title {
  font-size: 46px;
  font-family: SourceHanSansCN;
  font-weight: 800;
  color: #FFFFFF;
  line-height: 58px;
  position:absolute;
  left: 418px;
  top: 290px;

}
.img {
  position:absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.sub-title {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 40px;
  position:absolute;
  left: 418px;
  top: 359px;
  text-align: left;
}
.solicitude {
  box-sizing: border-box;
  padding: 113px 362px;
}
.so-title {
  font-size: 34px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 48px;
  text-align: left;
}
.so-sub-title {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #898989;
  line-height: 34px;
  text-align: left;
  margin-top: 7px;
  margin-bottom: 18px;
}

.so-imgs {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.so-imgs-item {
  width: 400px;
  height: 240px;
  position: relative;
  overflow: hidden;
}

.section {
  width: 100%;
  box-sizing: border-box;
  padding: 0px 362px;
  display: flex;
  flex-direction: row;
  text-align: left;
  margin-bottom: 90px;
}

.sec-left {
  width: 464px;
  height: 100%;
  margin-left: 85px;
}
.sec-title {
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 40px;
  margin-top: 64px;
}
.sec-sub-title {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #898989;
  line-height: 34px;
  margin-top: 11px;
}
.sec-left-img {
  width: 313px;
  height: 263px;
  margin-top: 250px;
  margin-left: 122px;
}
.sec-right {
  width: 100%;
  height: 100%;
  margin-left: 90px;
}
.sec-right-img {
  width: 500px;
  height: 455px;
}
.sec-r-title {
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 40px;
  margin-left: 35px;
  margin-top: 100px;
}
.sec-r-sub-title {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #898989;
  line-height: 34px;
  margin-left: 35px;
  margin-top: 2px;
}

.interview {
  width: 100%;
  box-sizing: border-box;
  padding: 91px 361px 67px 361px;
  height: 689px;
  text-align: left;
  background: url('../../assets/join-us-mianshi.png') no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: row;
}

.in-left {
  width: 522px;
  height: 100%;
}
.in-title {
  font-size: 34px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 48px;
}
.in-sub-title {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 34px;
  margin-top: 15px;
}

.in-right {
  width: 499px;
  height: 531px;
  /* background: #FFFFFF; */
  box-shadow: 0px 14px 12px -1px rgba(202, 202, 202, 0.19);
  border-radius: 8px;
  margin-left: 97px;
  padding: 49px 44px 0px 46px;
}
.in-item {
  margin-bottom: 17px;
}
.in-item-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
}
.in-dot {
  width: 12px;
  height: 12px;
  background: #F5A100;
  border-radius: 50%;
}
.in-r-title {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 36px;
  margin-left: 12px;
}
.in-item-detail {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  padding-left: 22px;
}

.job {
  width: 100%;
  height: 100%;
  background: #F5F6F7;
  box-sizing: border-box;
  padding: 118px 361px;
  position: relative;
  text-align: left;
}
.s-bg-1 {
  position:absolute;
  left: 0;
  top: 0;
  width: 759px;
  height: 256px;
}
.job-content {
  position: relative;
  width: 100%;
}
.job-title {
  font-size: 34px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 48px;
}

.job-phone {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 34px;
  margin-top: 12px;
}
.job-tips {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #898989;
  line-height: 34px;
  margin-top: 17px;
}

.job-list {
  width: 100%;
  margin-top: 65px;
  display: flex;
  flex-wrap: wrap;
  justify-content:flex-start;
}

.job-list-item {
  width: 210px;
  float: left;;
  height: 54px;
  background: #FFFFFF;
  border-radius: 4px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 54px;
  text-align: center;
  margin-right: 30px;
  margin-bottom: 21px;
  flex-shrink: 0;
  box-sizing: border-box;

}

.job-list-item-selected {
  background: #F5A100;
  box-shadow: 0px 0px 10px 2px rgba(173, 106, 32, 0.27);
  border-radius: 4px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}

.job-line {
  width: 100%;
  height: 1px;
  background: #D4D4D4;
  margin: 20px 0px;
}
.job-intro-item {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 25px;
  margin-top: 30px;
  text-align: left;
  margin-bottom: 4px;
}
.job-content-item {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #898989;
  line-height: 34px;
}
.job-intro-title {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 33px;
}
.hover-so-item {
  width: 400px;
  height: 240px;
  font-size: 28px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 240px;
  text-align: center;
  position:absolute;
  left: 0;
  top: 0;
}
.hover-so-item-bg {
  background: #F5A100;
  opacity: 0.9;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.hover-so-item-txt {
  position: relative;
  z-index: 99;
}
.so-img-hover {
  transform: scale(1.1);
  transition: transform 0.3s ease-out;
}

.more-red {
  width: 44px;
  height: 12px;
  margin-top: 30px;
}

img {
  width: 100%;
  height: 100%;
}

/* .slide-fade-enter-active {
  transition: opacity .5s;
}
.slide-fade-leave-active {

} */
/* .esayOut-enter-active, .esayOut-leave-active {
    opacity: 0;
    transition: opacity 0.25s
} */
.scale-enter-active{
  transform: scale(1.2);
  transition: transform 0.3s ease-out;
}
.scale-leave-active  {
  transform: scale(1.0);
  transition: transform 0.3s ease-out;
}

</style>