<template>
  <div
    class="introduce"
    :class="_isMobile() ? 'introduce-mobile' : ''"
    @click="clickTest"
  >
    <div class="background-mask"></div>
    <div class="intro-wrap ignore_max_width">
      <div class="intro-content">
        <div class="intro-content-left">
          <div class="intro-content-left-txt">
            <div class="intro-content-left-txt-title01">公司简介</div>
            <div class="intro-content-left-txt-title02">我-们-是-谁</div>
            <div class="intro-content-left-txt-title03">
              Company<br />Culture
            </div>
            <div class="intro-content-letf-add-flag"></div>
          </div>
          <div
            class="intro-content-left-item"
            @mouseenter="mouseEnter"
            @mouseleave="mouseLeave"
          >
            <transition name="slide-fade" mode="out-in">
              <div v-show="!show01" class="intro-no-img-left left-shadow">
                <div class="hover-title">客户价值</div>
                <div class="hover-txt">
                  提供灵活多样的一站式人力服务解决方案，协助互联网、金融、通讯、教育、地产等众多行业，从繁琐浩大的人力需求中解脱出来，专注自己的产品创新，同时减少投入、提高工作效率。高质量交付能协助客户实现有效的风险降低和成本控制，给员工定制培训专项技能，为客户提供强有力的技术保障。
                </div>
              </div>
            </transition>
            <div class="intro-img-txt intro-img-1">
              <div class="intro-content-right-item-txt-num">01</div>
              <div class="intro-content-right-item-txt-line"></div>
              <div class="intro-content-right-item-txt-title">关于京孚</div>
            </div>
          </div>
        </div>
        <div class="intro-content-right">
          <div class="intro-content-right-top">
            <div class="intro-right-txt01">激情永在，信心百倍，接受结果</div>
            <div class="intro-right-txt02">
              Be Passionate <br />
              Assertive and Own the Results
            </div>
            <div class="more-red"></div>
          </div>
          <div class="intro-content-right-bottom">
            <div
              class="intro-content-right-item"
              @mouseenter="mouseEnter2"
              @mouseleave="mouseLeave2"
            >
              <transition name="slide-fade" mode="out-in">
                <div v-show="!show02" class="intro-no-img">
                  <div class="hover-title">客户收益</div>
                  <div class="hover-txt">
                    京孚信息拥有多种专业技能人才和IT人才库，将在短时间内交付人员需求，使您可以把注意力更多的集中在业务能力上而不必为易变的人力需求而烦恼，为您提供人员从而降低您的培训成本。针对项目的灵活的资源配置模式使您可以得到对特定任务的适合资源，并且在需要的时间释出人员。
                  </div>
                </div>
              </transition>
              <div class="intro-img-txt intro-img-2">
                <div class="intro-content-right-item-txt-num">02</div>
                <div class="intro-content-right-item-txt-line"></div>
                <div class="intro-content-right-item-txt-title">客户收益</div>
              </div>
            </div>
            <div
              class="intro-content-right-item"
              @mouseenter="mouseEnter3"
              @mouseleave="mouseLeave3"
            >
              <transition name="slide-fade" mode="out-in">
                <div v-show="!show03" class="intro-no-img">
                  <div class="hover-title">京孚优势</div>
                  <div class="hover-txt">
                    为客户提前签订保密协议，定期的保密制度培训，完善的岗位监督审查和薪酬制度。同时提供紧密的员工关怀，过渡期重点维护，培养员工的规范开发习惯。严格的考核标准和全面的培训制度，是员工高效完成工作的有力保障。
                  </div>
                </div>
              </transition>
              <div class="intro-img-txt intro-img-3">
                <div class="intro-content-right-item-txt-num">03</div>
                <div class="intro-content-right-item-txt-line"></div>
                <div class="intro-content-right-item-txt-title">京孚优势</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="intro-red-flag">
        <div class="intro-red-flag-item"></div>
      </div>
    </div>
    <div class="next-button"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show01: true,
      show02: true,
      show03: true,
    }
  },

  methods: {
    clickTest() {
      // alert('点击了')
    },

    mouseEnter() {
      this.show01 = false
    },
    mouseLeave() {
      this.show01 = true
    },
    mouseEnter2() {
      this.show02 = false
    },
    mouseLeave2() {
      this.show02 = true
    },
    mouseEnter3() {
      this.show03 = false
    },
    mouseLeave3() {
      this.show03 = true
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
  },
}
</script>

<style scoped>
@font-face {
  font-family: Bebas;
  src: url('../../assets/font/BEBAS___.TTF') format('truetype');
}

div {
  box-sizing: border-box;
}

.introduce {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}
.introduce-mobile {
  height: 1080px !important;
}
.intro-wrap {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  z-index: 9;
  position: relative;
}
.intro-content {
  display: flex;
  flex-direction: row;
  margin-top: 73px;
  z-index: 10;
}
.intro-content-left {
  width: 523px;
  height: 795px;
  background: #f5a100;
  border-radius: 4px;
  background: url('../../assets/company_background.png') no-repeat;
  background-size: 100%;
  padding-left: 43px;
  padding-top: 41px;
  position: relative;
  z-index: 11;
}
.intro-content-left-item {
  width: 350px;
  height: 580px;
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 999;
}
.left-shadow {
  box-shadow: -4px -9px 14px 0px rgba(191, 106, 0, 0.46);
  border-radius: 4px;
}

.intro-content-left-txt {
  width: 100%;
  height: 265px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  position: relative;
  z-index: 12;
}
.intro-content-left-txt-title01 {
  height: 48px;
  font-size: 34px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 48px;
}
.intro-content-left-txt-title02 {
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: 22px;
  text-align: left;
}
.intro-content-left-txt-title03 {
  height: 120px;
  font-size: 60px;
  font-family: Bebas;
  color: #ffffff;
  line-height: 60px;
  opacity: 0.31;
}

.intro-content-right {
  display: flex;
  flex-direction: column;
  z-index: 12;
}
.intro-content-right-top {
  width: 676px;
  height: 235px;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.intro-content-right-bottom {
  width: 676px;
  height: 560px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.intro-content-right-item {
  width: 330px;
  height: 560px;
  margin-left: 8px;
  overflow: hidden;
  position: relative;
}
.intro-content-right-item:hover {
  height: 560px;
}
.intro-content-right-item-img {
  width: 330px;
  height: 530px;
  z-index: 0;
}
.intro-right-txt01 {
  margin-top: 20px;
  height: 34px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #898989;
  line-height: 34px;
}
.intro-right-txt02 {
  height: 40px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #898989;
  line-height: 20px;
}
.intro-red-flag {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  margin-top: 7px;
}
.intro-red-flag-item {
  width: 117px;
  height: 46px;
  background: #c30f23;
  border-radius: 4px;
}
.intro-content-right-item-txt {
  width: 100%;
  position: relative;
  text-align: left;
  left: 0;
  top: -200px;
  padding: 0px 20px;
  z-index: 10;
}
.intro-content-right-item-txt-num {
  height: 85px;
  font-size: 64px;
  font-family: Bebas;
  color: #ffffff;
  line-height: 85px;
}
.intro-content-right-item-txt-line {
  margin: 15px 0px;
  width: 53px;
  height: 10px;
  background: #f5a100;
}
.intro-content-right-item-txt-title {
  height: 40px;
  font-size: 28px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 40px;
}
.background-mask {
  position: absolute;
  right: 0;
  top: -400px;
  width: 632px;
  height: 808px;
  background: url('../../assets/background_mask.png') no-repeat;
  background-size: 100%;
  z-index: 0;
}
.hover-title {
  font-size: 30px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 42px;
  text-shadow: -3px -3px 16px rgba(132, 88, 2, 0.33);
  margin: 76px 32px 12px 32px;
  text-align: left;
  position: relative;
}
.hover-txt {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 38px;
  margin: 0px 30px;
  text-align: justify;
  position: relative;
}
.next-button {
  width: 38px;
  height: 20px;
  position: absolute;
  bottom: 36px;
  background: url('../../assets/next-page.png') no-repeat;
  background-size: 100%;
}
.img {
  width: 100%;
  height: 100%;
}
.intro-no-img-left {
  height: 560px;
  background: #f5a100;
  position: absolute;
  left: 20px;
  top: 20px;
  border-radius: 4px;
  box-sizing: border-box;
}

.intro-no-img {
  width: 100%;
  height: 560px;
  background: #f5a100;
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 4px;
}

.intro-img-txt {
  width: 100%;
  height: 530px;
  padding-top: 332px;
  padding-left: 30px;
  padding-right: 30px;
  padding: 332px 30px 28px 30px;
  text-align: left;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 30px;
}
.intro-img-1 {
  background: url('../../assets/home-01.png') no-repeat;
  background-size: 100%;
  margin-left: 20px;
  margin-top: 50px;
}
.intro-img-2 {
  background: url('../../assets/home-02.png') no-repeat;
  background-size: 100%;
}
.intro-img-3 {
  background: url('../../assets/home-03.png') no-repeat;
  background-size: 100%;
}
.more-red {
  margin-top: 37px;
  z-index: 4;
  width: 44px;
  height: 14px;
  background: url('../../assets/more-red.png') no-repeat;
  background-size: 100%;
}
.intro-content-letf-add-flag {
  position: absolute;
  width: 31px;
  height: 31px;
  right: 62px;
  top: 0px;
  background: url('../../assets/back-add-flag.png') no-repeat;
  background-size: 100%;
  z-index: 99;
}

.slide-fade-enter-active {
  animation: fade 0.25s ease-in-out;
}
.slide-fade-leave-active {
  animation: fade 0.25s ease-in-out reverse;
}
@keyframes fade {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
