<template>
  <div class="link-container ignore_max_width" :class="_isMobile() ? 'introduce-mobile':''" v-cloak>
    <div class="link-top-block"></div>
    <div class="link-title">链接人才与发展</div>
    <div class="link-line"></div>
    <div class="link-sub-title">助力企业，联动业务<br />优化人才，合理配置，同频成长</div>
    <div class="link-content">
      <div class="link-content-left"></div>
      <div class="link-content-right">
          <div class="link-content-background"></div>
          <div class="link-content-row">
            <div class="link-content-row-item" v-for="(item,index) in item_list" :key="index" @mouseenter="mouseenterEvent(item)" @mouseleave="mouseleaveEvent(item)">
                <img :src="item.bg" />
                <div class="link-content-item-title">{{item.title}}</div>
                <!-- <div class="link-content-item-line"></div> -->
                <transition name="slide-fade">
                  <div class="hover-content" v-if="item.hover" v-cloak>
                    <div class="hover-title">{{item.hover_title}}</div>
                    <div class="hover-sub-title">{{item.hover_sub_title}}</div>
                    <div class="hover-button" @click="itemButtonEvent(item.hover_button_href)">
                    </div>
                  </div>
                </transition>
            </div>
            <div class="link-content-row-next" ></div>
          </div>
      </div>
    </div>

  </div>
</template>

<script>

import link01 from '../../assets/link-01.png'
import link02 from '../../assets/link-02.png'
import link03 from '../../assets/link-03.png'

export default {

  data() {
    return {
      item_list: [
        {
          bg: link01,
          title: '解决方案',
          hover_title: '解决方案',
          hover_sub_title: '京孚信息为客户提供IT咨询，业务规划，行业解决方案，定制化开发与实施到系统维护服务的一站式全生命周期，价值链的IT解决方案服务，覆盖互联网，金融，通讯，教育，地产等众多行业。',
          hover_button_href: '/solution',
          hover: false,
        },
         {
          bg: link02,
          title: '合作伙伴',
          hover_title: '合作伙伴',
          hover_sub_title: '致力于建设战略合作联盟，共同为每个行业提供领先的解决方案组合，帮助客户建立更加系统、完善的业务解决方案。',
          hover_button_href: '/partner',
          hover: false,
        },
         {
          bg: link03,
          title: '加入京孚',
          hover_title: '加入京孚',
          hover_sub_title: '在京孚 每个人都有充分机会去解决最重要的问题，如果你希望肩负这种责任那么立即加入京孚，这里的资源可以帮助你找到自己的方向',
          hover_button_href: '/joinUs',
          hover: false,
        }
      ]
    }
  },
  methods: {
    _isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
    },
    itemButtonEvent(href) {
      this.$router.push({
        path: href,
      })
    },
    mouseenterEvent(item) {
      item.hover = true
    },
    mouseleaveEvent(item) {
       item.hover = false

    }
  }
}
</script>

<style scoped>

div {
  box-sizing: border-box;
}
.introduce-mobile {
  height: 1080px !important;
}

.link-container {
  width: 100%;
  height: 100vh; 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 362px;
  box-sizing: border-box;
  max-width: 1920px;
}
.link-top-block {
  height: 140px;
  width: 100%;
}
.link-title {
  height: 48px;
  font-size: 34px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 48px;
  text-align: center;
}
.link-line {
  width: 53px;
  height: 10px;
  background: #F5A100;
  margin: 10px 0px;
}
.link-sub-title {
  height: 68px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #898989;
  line-height: 34px;
}
.link-content {
  display: flex;
  flex-direction: row;
  margin-top: 54px;
}
.link-content-left {
  width: 4px;
  height: 180px;
  background: #C30F23;
}
.link-content-right {
  width: 100%;
  height: 100%;
  position: relative;
}
.link-content-background {
  width: 1043px;
  height: 514px;
  background: #F5F6F7;
  border-radius: 4px;
  margin-left: 152px;
  margin-top: 83px;
}
.link-content-row {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 78px;
  left: 57px;
}
.link-content-row-item {
  width: 360px;
  height: 520px;
  position: relative;
  overflow: hidden;
  /* margin-right: 8px; */
}
.link-content-row-next {
  position: absolute;
  bottom: -34px;
  right: 8px;
  width: 44px;
  height: 14px;
  background:url('../../assets/more-red.png') no-repeat;
  background-size: 100%;
}
.link-content-item-title {
  height: 40px;
  font-size: 28px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 40px;
  position: absolute;
  bottom: 70px;
  left: 42px;
  text-align: left;
}
.link-content-item-line {
  width: 100%;
  height: 11px;
  background: #F5A100;
  position: absolute;
  bottom: 0px;
}

.hover-content {
  width: 360px;
  height: 520px;
  background: #F5A100;
  position:absolute;
  left: 0;
  top: 0;
  padding: 0px 47px;
  font-size: 18px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 32px;
  text-align: left;
}
.hover-title {
  font-size: 28px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 40px;
  margin-top: 55px;
}
.hover-button {
  width: 110px;
  height: 40px;
  cursor: pointer;
  background: url('../../assets/more-content-white.png') no-repeat;
  background-size: 100%;
}
.hover-button:hover {
  background: url('../../assets/more-content-hover.png') no-repeat;
  background-size: 100%;
}
.hover-sub-title {
  height: 340px;
  text-align: justify;
}

img {
  width: 100%;
  height: 100%;
}

.slide-fade-enter-active {
  animation:fade 0.25s
}
.slide-fade-leave-active {
  animation: fade 0.25s reverse;
}
@keyframes fade {
0% {
    transform: translateY(520px);
  }
  
  100% {
    transform: translateY(0);
  }
}

</style>