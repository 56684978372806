<template>
   <div class="container">
    <Header @scroll="scrollEvent" />
    <div class="licence">
      <img class="licence-img" src="../../assets/jingfu-e-licence.jpg" />
    </div>
     <Footer @scroll="scrollEvent" />
   </div>
</template>
<script>
import Header from '../../components/Header'
import Footer from '../../components/Footer'


export default {
  
  components: { Header,Footer},


  methods: {

     scrollEvent() {

    },
  }

}
</script>

<style scoped>

.licence {
  display: flex;
  margin-top: 60px;
  justify-content: center;
  max-width: 1920px;
  height: 1380px;
}
.licence-img {
  width: 100%;
  height: 100%;
}

</style>