<template>
  <div class="banner-container">
    <swiper id="bannerSwiper" ref="mySwiper" :options="swiperOptions">
      <swiper-slide>
        <div class="banner-item">
          <img class="back-img" src="../../assets/banner/banner-1.png" />
          <div class="content">
            <div class="item">
              <div class="item-title">尊重个人</div>
              <div>Respect for the Individual</div>
            </div>
            <div class="item">
              <div class="item-title">服务客户</div>
              <div>Customers Service</div>
            </div>
            <div class="item">
              <div class="item-title">追求卓越</div>
              <div>Strive for Excellence</div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="banner-item">
          <img class="back-img" src="../../assets/banner/banner-2.png" />
          <div class="content-mid">
            <div class="red-item"></div>
            <div class="item-title mid-title">深孚众望<br />不负众望</div>
            <div class="item mid-sub-title">为各行业人力资源注入全新价值</div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="banner-item">
          <img class="back-img" src="../../assets/banner/banner-3.png" />
          <div class="content-mid">
            <div class="red-item"></div>
            <div class="item-title mid-title">有孚盈缶<br />终来有它</div>
            <div class="item mid-sub-title">
              京孚信息，专注IT外包，为万千企业解决开发和<br />用工问题
            </div>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination" id="custom"></div>
    </swiper>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        direction: 'horizontal',
        loop: true,
        autoplay: {
          delay: 6000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        observer: true,
        observeParents: true,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'custom',
          clickable: true,
          renderCustom: function(swiper, current, total) {
            var customPaginationHtml = ''
            for (let i = 0; i < total; i++) {
              if (i == current - 1) {
                customPaginationHtml += `<span class="my-bullet-active" index="${i}" ></span>`
              } else {
                customPaginationHtml += `<span class="my-bullet" index="${i}" ></span>`
              }
            }
            return customPaginationHtml
          },
        },
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    },
  },
  mounted() {
    // console.log('Current Swiper instance object', this.swiper)
    // this.swiper.slideTo(0, 500, false)
    this.customBox = document.getElementById('custom')
    this.customBox.addEventListener('click', this.handleClick, false)
  },
  destroyed() {
    this.customBox.removeEventListener('click', this.handleClick, false)
  },
  methods: {
    pageClick(index) {
      console.log(index)
    },
    handleClick(e) {
      const current = e.target
      if (current.attributes['index']) {
        const toCount = current.attributes['index'].value || ''
        const toIndex = Number(toCount) + 1
        this.swiper.slideTo(toIndex, 500, false)
      }
    },
  },
}
</script>

<style scoped>
@font-face {
  font-family: SourceHanSansCN;
  src: url('../../assets/font/SourceHanSansCN-Heavy.ttf') format('truetype');
}

.banner-container {
  width: 100%;
  height: 666px;
}
.banner-item {
  width: 1920px;
  height: 665px;
  position: relative;
}
.back-img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.swiper-pagination {
  /* position:absolute; */
  /* left: 0; */
  width: 100%;
  height: 20px;
  margin-left: 25%;
  z-index: 999;
}
.content {
  position: relative;
  padding: 286px 407px 274px 418px;
  display: flex;
  justify-content: space-between;
  z-index: 22;
}
.content-mid {
  position: relative;
  padding: 281px 361px 200px 361px;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  z-index: 99;
  text-align: left;
}
.item {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
}
.item-title {
  font-size: 60px;
  font-family: SourceHanSansCN;
  font-weight: 800;
  color: #ffffff;
  line-height: 60px;
  letter-spacing: 4px;
}
.red-item {
  width: 117px;
  height: 46px;
  background: #c30f23;
  border-radius: 4px;
  position: absolute;
}
.mid-title {
  margin-left: 57px;
  margin-top: 20px;
  z-index: 10;
  line-height: 70px;
}
.mid-sub-title {
  margin-left: 57px;
  margin-top: 20px;
  z-index: 10;
}

>>> .my-bullet {
  width: 56px;
  height: 7px;
  display: inline-block;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.3);
  margin: 0 2.5px;
}
>>> .my-bullet-active {
  width: 56px;
  height: 7px;
  display: inline-block;
  cursor: pointer;
  background: #f5a100;
  margin: 0 2.5px;
}
</style>
