import Vue from 'vue'
import Router from 'vue-router'

import index from '../pages/home'
import m_index from '../pages/home/m_index.vue'
import solution from '../pages/solution'
import partner from '../pages/partner'
import joinUs from '../pages/join'
import elicence from '../pages/eLicence'


// const originalPush = Router.prototype.replace
// Router.prototype.replace = function replace(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

function _isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}

Vue.use(Router);
let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      component: _isMobile() ? m_index : index,
      meta: {
        title: '京孚-北京京孚信息技术有限公司'
      }
    },
    {
      path: '/solution',
      name: 'solution',
      component: solution,
      meta: {
        title: '解决方案-北京京孚信息技术有限公司'
      }
    },
    {
      path: '/partner',
      name: 'partner',
      component: partner,
      meta: {
        title: '合作伙伴-北京京孚信息技术有限公司'
      }
    },
    {
      path: '/joinUs',
      name: 'joinUs',
      component: joinUs,
      meta: {
        title: '加入京孚-北京京孚信息技术有限公司'
      }
    },
    {
      path: '/elicence',
      name: 'elicence',
      component: elicence,
      meta: {
        title: '电子营业执照-北京京孚信息技术有限公司'
      }
    }
  ]
});

export default router
