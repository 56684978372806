<template>
  <div class="container">
    <Header @scroll="scrollEvent" />
    <div class="img-content">
      <img class="img" src="../../assets/banner-partner.png" />
      <div class="red-flg"></div>
      <div class="title">合作伙伴</div>
      <div class="sub-title">
        致力于建设战略合作联盟，共同为每个行业提供领先的解决方案<br />组合，帮助客户建立更加系统、完善的业务解决方案。
      </div>
    </div>
    <div class="contact" ref="contact">
      <div class="left">
        <div class="left-title">合作带来更多可能</div>
        <div class="left-detal">
          京孚信息在快速发展过程中，将战略合作联盟视为业务持续发展的重要基础。紧密的合作将实现优势互补，并有利于技术与产品创新，形成卓越的产品服务组合，满足客户的多样化需求，共同为客户打造极致的用户体验和系统的解决方案，从而一起实现产品价值的飞跃提升。
        </div>
        <div class="con-img"></div>
        <div class="phone">
          欢迎随时致电 010-82085825 <br />或者发邮件hr@jingfuchina.com给我们留言
        </div>
      </div>
      <div class="right">
        <div class="input-item">
          <input
            v-model="formData.name"
            type="text"
            class="input"
            @focus="onfocusEvent('姓名')"
            @blur="onblurEvent"
          />
          <div
            v-show="!formData.name && this.onfocus != '姓名'"
            class="placeholder"
          >
            姓名<span class="red-flag">*</span>
          </div>
        </div>
        <div class="input-item">
          <input
            v-model="formData.post"
            type="text"
            class="input"
            @focus="onfocusEvent('职务')"
            @blur="onblurEvent"
          />
          <div
            v-show="!formData.post && this.onfocus != '职务'"
            class="placeholder"
          >
            职务<span class="red-flag">*</span>
          </div>
        </div>
        <div class="input-item">
          <input
            v-model="formData.company"
            type="text"
            class="input"
            @focus="onfocusEvent('公司名称')"
            @blur="onblurEvent"
          />
          <div
            v-show="!formData.company && this.onfocus != '公司名称'"
            class="placeholder"
          >
            公司名称<span class="red-flag">*</span>
          </div>
        </div>
        <div class="input-item">
          <input
            v-model="formData.email"
            type="text"
            class="input"
            @focus="onfocusEvent('邮箱')"
            @blur="onblurEvent"
          />
          <div
            v-show="!formData.email && this.onfocus != '邮箱'"
            class="placeholder"
          >
            邮箱<span class="red-flag">*</span>
          </div>
        </div>
        <div class="input-item">
          <input
            v-model="formData.phone"
            type="text"
            class="input"
            @focus="onfocusEvent('手机')"
            @blur="onblurEvent"
          />
          <div
            v-show="!formData.phone && this.onfocus != '手机'"
            class="placeholder"
          >
            手机<span class="red-flag">*</span>
          </div>
        </div>
        <textarea
          v-model="formData.content"
          placeholder="我们能帮你做什么？"
        ></textarea>
        <div class="input-bottom">
          <div class="submit-button" @click="submit">
            立即提交
          </div>
          <div class="privacy">
            我们尊重您的隐私
          </div>
        </div>
      </div>
    </div>
    <Footer @scroll="scrollEvent" />
  </div>
</template>

<script>
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Request from '../../../request.js'

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      onfocus: '',
      formData: {
        name: '',
        post: '',
        company: '',
        email: '',
        phone: '',
        content: '',
      },
    }
  },

  methods: {
    submit() {
      if (!this.formData.name) {
        alert('请填写用户名称')
        return
      }
      if (!this.formData.post) {
        alert('请填写职务')
        return
      }
      if (!this.formData.company) {
        alert('请填写公司名称')
        return
      }
      if (!this.formData.email) {
        alert('请填写邮箱')
        return
      }
      if (!this.formData.phone) {
        alert('请填写手机号码')
        return
      }

      Request({
        url: '/add',
        method: 'post',
        data: this.formData,
      })
        .then(() => {
          alert('提交成功')
          this.formData = {
            name: '',
            post: '',
            company: '',
            email: '',
            phone: '',
            content: '',
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    onfocusEvent(e) {
      this.onfocus = e
    },
    onblurEvent() {
      this.onfocus = ''
    },
    scrollEvent(query) {
      if (query && query.id) {
        this.$refs[query.id].scrollIntoView()
      } else {
        document.documentElement.scrollTop = 0
      }
    },
  },
  mounted() {
    this.scrollEvent(this.$route.query)
  },
}
</script>

<style scoped>
@font-face {
  font-family: SourceHanSansCN;
  src: url('../../assets/font/SourceHanSansCN-Heavy.ttf') format('truetype');
}

.img-content {
  width: 100%;
  height: 665px;
  position: relative;
}
.red-flg {
  position: absolute;
  width: 117px;
  height: 46px;
  background: #c30f23;
  border-radius: 4px;
  left: 362px;
  top: 283px;
}
.title {
  font-size: 46px;
  font-family: SourceHanSansCN;
  font-weight: 800;
  color: #ffffff;
  line-height: 58px;
  position: absolute;
  left: 418px;
  top: 290px;
}
.img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.sub-title {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
  position: absolute;
  left: 418px;
  top: 359px;
  text-align: left;
}
.contact {
  width: 100%;
  height: 989px;
  box-sizing: border-box;
  padding: 115px 362px;
  display: flex;
  flex-direction: row;
  text-align: left;
}
.left {
  width: 532px;
  height: 100%;
}
.right {
  width: 100%;
  height: 100%;
  margin-left: 91px;
  margin-top: 55px;
}
.left-title {
  font-size: 34px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 48px;
}
.left-detal {
  width: 533px;
  height: 170px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #898989;
  line-height: 34px;
  margin-top: 6px;
}
.phone {
  height: 50px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #363636;
  line-height: 25px;
  width: 532px;
  margin-top: 20px;
  white-space: nowrap;
  background: url('../../assets/more-red.png') no-repeat left 5px;
  background-size: 44px 12px;
  text-indent: 48px;
}
.privacy {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #898989;
  line-height: 34px;
  margin-top: 23px;
}
.con-img {
  width: 532px;
  height: 286px;
  margin-top: 14px;
  background: url('../../assets/partner-left-contact.png') no-repeat;
  background-size: 100% 100%;
}
.con-img-more {
  width: 44px;
  height: 12px;
  margin-right: 10px;
  margin-bottom: -10px;
}
.img-more {
  width: 100%;
  height: 100%;
}

.input-item {
  margin-bottom: 17px;
  color: #000000;
  position: relative;
  z-index: 0;
  width: 575px;
  height: 64px;
  background: #f5f6f7;
  border-radius: 4px;
  border: 0px solid #ffffff;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
  box-sizing: border-box;
  padding: 0px 25px;
}

.submit-button {
  width: 173px;
  height: 63px;
  background: rgb(234, 163, 57);
  border-radius: 4px;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 63px;
  text-align: center;
  margin-top: 18px;
  cursor: pointer;
  background: url('../../assets/submit-nomal.png') no-repeat;
  background-size: 100%;
}
.submit-button:hover {
  background: url('../../assets/submit-hover.png') no-repeat;
  background-size: 100%;
}

.input-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

img {
  width: 100%;
  height: 100%;
}

input {
  background: rgba(1, 1, 1, 0);
  border: rgba(1, 1, 1, 0);
  width: 173px;
  height: 63px;
}

textarea {
  width: 575px;
  height: 141px;
  background: #f5f6f7;
  border-radius: 4px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
  border: 0px solid #ffffff;
  box-sizing: border-box;
  padding: 10px 25px;
  max-height: 141px;
  resize: none;
}

input:focus,
textarea:focus {
  outline: none;
}
.input {
  position: relative;
  z-index: 1;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
}
.placeholder {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 575px;
  height: 64px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 64px;
  box-sizing: border-box;
  padding: 0px 25px;
}
.red-flag {
  width: 11px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #dc3250;
  line-height: 20px;
  text-align: center;
}
</style>
