<template>
  <transition name="fade-back">
  <div v-show="show" class="wrap-head" :class="transparent ? '':'wrap-head-transparent'" @mouseleave="leave()">
      <div class="head-content"> 
        <div class="logo" :class="transparent ? 'logo-background-white':'logo-background-nomal'" @click="go_home()">
        </div>
        <ul class="menu-list"> 
            <li class="menu-item" v-for="(item,index) in itemList" :key="index"  >
              <div class="menu-item-title" @mouseenter="enterEvent(index)" @mouseleave="leaveEvent(index)">
                 <a class="txt" :class="transparent ? 'txt-transparent':''" @click="clickEvent(item)">
                {{item.name}}
                </a>
                <div v-if="menuHoverIndex == index" class="txt-red-flag txt-red-flag-selected" ></div>
                <div v-else class="txt-red-flag" :class="get_red_flag(item)"></div>
              </div>
              <ul class="sub-menu-list" :style="menuHoverIndex == index ? 'display: block;': 'display: none;'" @mouseenter="enterEvent(index)" @mouseleave="leaveEvent(index)">
                <li class="sub-menu-item" v-for="(subItm,subIndex) in item.child" :key="subIndex">
                  <a class="sub-menu-item-txt" @click="clickEvent(subItm)"> {{subItm.name}} </a>
                </li>
              </ul>
            </li>
        </ul>
      </div>
       <transition name="slide-fade" mode="out-in">
        <div v-show="showBg" class="head-bg" :style="bg_height" ></div>
       </transition>
  </div>
  </transition>
</template>

<script>
export default {

  props: {
    isTransparent: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      queryId: '',
      showBg: false,
      show: true,
      showTop: "",
      menuHoverIndex: -1,
      mouse:[],
      itemList: [{
        name: '首页',
        url: '/',
        query: {
          id: ''
        }
      },{
        name: '公司简介',
        url: '/',
        query: {
          id:'introduce',
        }
      },{
        name: '业务领域',
        url: '/',
        query: {
          id:'business',
        }
      },{
        name: '解决方案',
        url: '/solution',
        query: {},
        child: [
          {
            name: '行业领域',
            url: '/solution',
            query: {
              id: 'industry'
            }
          },
          {
            name: '服务流程',
            url: '/solution',
            query: {
              id: 'service'
            }
          },
          {
            name: '成功案例',
            url: '/solution',
            query: {
              id: 'successCase'
            }
          }
         ]
      },{
        name: '合作伙伴',
        url: '/partner',
        query: {},
        child: [
          {
            name: '联系我们',
            url: '/partner',
            query: {
              id: 'contact'
            }
          }
        ]
      },{
        name: '加入京孚',
        url: '/joinUs',
        query: {},
        child: [
          {
            name: '员工关怀',
            url: '/joinUs',
            query: {
              id: 'solicitude'
            }
          },
           {
            name: '面试过程',
            url: '/joinUs',
            query: {
              id: 'interview'
            }
          },
           {
            name: '热招职位',
            url: '/joinUs',
            query: {
              id: 'hot_job'
            }
          }
        ]
      }]

    }
  },
  watch: {
    showTop:{
      handler(val,old) {
        if (val < 100) {
          this.show = true
          return
        }
        if (val > old) {
          this.show = false
        } else {
          this.show = true
        }
      }
    },
    showBg: {
      deep: true,
      handler(val) {
        if (!val) {
          this.menuHoverIndex = -1
        } 
      }
    }
  },

  computed:{
    transparent() {
      return this.isTransparent && !this.showBg
    },
    
    bg_height() {
      let max = 1
      this.itemList.map( item => {

        if (item.child) {
           max = item.child.length > max ? item.child.length : max;
        }
        return item
      })
      return  max * 70 + 'px'
    },
  },

  mounted() {
    this.queryId = this.$route.query.id || '';
    window.addEventListener('scroll', this.handleScroll); // 监听页面滑动
  },

  methods: {
    handleScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // let tmp = Math.abs(scrollTop - this.scrollTop)
      // if (tmp > 100) {
      //   if (scrollTop > this.scrollTop) {
      //     this.show = false
      //   } else {
      //     this.show = true
      //   }
      //   this.scrollTop = scrollTop
      // } 
      this.showTop = scrollTop;
    },
    get_red_flag(item) {
      // 先区分是否是当前路由
      if (this.showBg) {
        return ''
      }

      if (this.$route.path == item.url) {
        if (this.$route.path == '/') {
          if(this.queryId == item.query.id) {
            return ''
          } else {
            return 'txt-red-flag-transparent'
          }
        }
        return 'txt-red-flag-selected'
      }
      return 'txt-red-flag-transparent'
    },
    enterEvent(index) {
      if (this.showBg) {
         this.menuHoverIndex = index
      } else {
        setTimeout(() => {
          this.menuHoverIndex = index
        }, 100);
      }
      this.showBg = true
     
    },
    leaveEvent(index) {
      this.mouse[index] = false
      this.menuHoverIndex = -1
    },
    leave() {
      this.showBg = false
    },

    go_home() {
      this.clickEvent({
        name: '首页',
        url: '/',
        query: {}
      })
    },
    clickEvent(item) {
      if(this.$route.path == item.url) {
        const queryId = item.query && item.query.id || ''
        this.queryId = queryId
        this.$emit('scroll',item.query)
      } else {
        this.$router.push({
          path: item.url,
          query: item.query 
        })
      }
    }
  }

}
</script>

<style scoped>

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
  height: 100%;
}

.wrap-head {
  display: block;
  width: 100%;
  height: 126px;
  z-index: 999999;
  position: fixed;
  left: 0;
  top: 0;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.wrap-head-transparent {
  background: white;
}


.head-content {
  display: block;
  width: 1200px;
  height: 126px;
  margin: 0px auto;
  position: relative;
  z-index: 2;
  color: #FFF;
}
.logo {
    display: block;
    height: 73px;
    width: 166px;
    position: absolute;
    left: 0px;
    top: 20px;
}
.logo-background-nomal {
  background: url('../../assets/logo_nomal.png') no-repeat;
  background-size: 100%;
}
.logo-background-white {
  background: url('../../assets/logo_white.png') no-repeat;
  background-size: 100%;
}
.menu-list {
    display: block;
    height: 126px;
    position: absolute;
    right: 50px;
    top: 0px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s linear;
    transform: translate(0px,0);
    padding: 20px 0px;

}
.menu-item {
    display: inline-block;
    box-sizing: border-box;
    padding: 28px 0px;
    list-style: none;
    /* margin-left: 65px; */
    position: relative;
    float: left;
    /* line-height: 72px; */
}
.txt {
  display: block;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #666666;
  line-height: 25px;
}
.txt-transparent {
  color: #fff;
}
.txt-666 {
  color: #666666;
}

.txt:hover {
  color: #666666;
}
.txt-red-flag {
  width: 24px;
  height: 4px;
  background: #FFF;
  margin: 6px 0px;
}
.txt-red-flag-transparent {
  background: #FFF;
  opacity: 0;
}

.menu-item-title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0px 32px;
}
.menu-item-title:hover .txt-red-flag {
  background: #C30F23 !important;
}

.txt-red-flag-selected {
  background: #C30F23;
}


.head-bg {
  width: 100%; 
  height: 250px; 
  background:rgba(255,255,255,1);
  z-index: 1; 
  position: absolute;
  top: 0px; 
  left: 0px; 
  -webkit-transform-origin:50% 0%;
}
.sub-menu-list {
    display: none;
    position: absolute;
    width: 160px;
    text-align: center;
    z-index: 2;
    margin: 0;
    padding: 0;
    left: 50%;
    margin-left: -80px;
}
.sub-menu-item {
    /* display: block; */
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #898989;
    line-height: 35px;
    margin: 10px 0px;
  }
.sub-menu-item-txt:hover {
  color: #666666;
}

.slide-fade-enter-active {
  animation:fade 0.25s
}
.slide-fade-leave-active {
  animation: fade 0.25s reverse;
}
@keyframes fade {
0% {
    transform: translateY(-100%);
  }
  
  100% {
    transform: translateY(0);
  }
}
.fade-back-enter-active {
  animation:fade-back 0.25s
}
.fade-back-leave-active {
  animation: fade-back 0.25s reverse;
}

@keyframes fade-back {
0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}


</style>