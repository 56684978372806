<template>
  <div class="footer-container">
    <div class="footer-content">
      <div class="footer-top">
        <div class="footer-left">
          <div class="logo" @click="goHome()" >
          </div>
          <div class="footer-info-item" v-for="(item,index) in info_list" :key="index"><a>{{item}}</a></div>
        </div>
        <div class="footer-right">
            <div class="footer-menu-title" v-for="(mItem,index) in menu_list" :key="index">
              <div class="footer-menu-sub-title" v-for="(sItem,sIndex) in mItem.child" :key="sIndex" @click="clickEvent(sItem)">
                <a>{{sItem.name}}</a>
              </div>
            </div>
        </div>
      </div>
      <div class="footer-bottom">
      <span style="margin-right:10px"> © 2020-2021 北京京孚信息技术有限公司</span>
      <span style="margin-right:10px"><a href="https://beian.miit.gov.cn/" rel="nofollow" target="_blank" style="color:#e4e4e4;text-decoration-line: none">京ICP备12043664号-18</a> </span>
      <!-- <span style="margin-right:10px">增值电信许可：京B2-20192416 B1.B2-20160181</span> <br /> -->
      <!-- <span style="margin-right:10px">广播电视节目制作许可：(京）字第15738号</span> -->
      <span style="margin-right:10px"> <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802033514" style="color:#e4e4e4;text-decoration-line: none">京公网安备 11010802033514号</a> </span>
      <span style="margin-right:10px"> <a href="/elicence" style="color:#e4e4e4;text-decoration-line: none">电子营业执照</a></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {

      menu_list: [
        {
          child:[
            {
              name: '公司简介',
              url: '/',
              query: {
                id:'introduce',
              }
            }
          ]
        },
        {
          child:[ 
            {
              name: '业务领域',
              url: '/',
              query: {
                id:'business',
              }
            }
          ]
        },
        {
          child: [
            {
              name: '解决方案',
              url: '/solution',
              query: {},
            },
            {
              name: '行业领域',
              url: '/solution',
              query: {
                id: 'industry'
              }
            },
            {
              name: '服务流程',
              url: '/solution',
              query: {
                id: 'service'
              }
            },
            {
              name: '成功案例',
              url: '/solution',
              query: {
                id: 'successCase'
              }
            }
          ]
        },
        {
          child: [
            {
              name: '合作伙伴',
              url: '/partner',
              query: {},
            },
            {
              name: '联系我们',
              url: '/partner',
              query: {
                id: 'contact'
              }
            }
          ]
        },
         {
          child: [
            {
              name: '加入京孚',
              url: '/joinUs',
              query: {},
            },
            {
              name: '员工关怀',
              url: '/joinUs',
              query: {
                id: 'solicitude'
              }
            },
            {
              name: '面试过程',
              url: '/joinUs',
              query: {
                id: 'interview'
              }
            },
            {
              name: '热招职位',
              url: '/joinUs',
              query: {
                id: 'hot_job'
              }
            }
          ]
        },
      ],

      info_list: [
        '地址 : 北京市海淀区学院路51号首享科技大厦10层',
        '邮编 : 100191',
        '电话 : 010-8208 8618',
        '传真 : 010-8208 5825',
        '邮箱 : hr@jingfuchina.com',
        '网址 : http://www.jingfuchina.com'
      ]

    }
  },
  methods: {
    goHome() {
      let item = {
        name: '首页',
        url: '/',
        query: {
          id: ''
        }
      }
      this.clickEvent(item)
    },
    clickEvent(item) {
      if(this.$route.path == item.url) {
        this.$emit('scroll',item.query)
      } else {
        this.$router.push({
          path: item.url,
          query: item.query 
        })
      }
    }
  }

}
</script>

<style scoped>

 div {
  box-sizing: border-box;
}

.footer-container {
  height: 757px;
  display: flex;
  background: url('../../assets/foot_bg.png') no-repeat;
  background-size: 100%;
  padding: 0;
  margin: 0;
}
.footer-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  padding: 132px 361px;
}

.footer-follow {
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #c4c4c4;
  line-height: 25px;
  text-align: left;
  margin: 47px 0px;
}

.footer-bottom {
  height: 54px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #c4c4c4;
  line-height: 27px;
  text-align: left;
  margin-top: 56px;
}
.footer-info-item {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #c4c4c4;
  line-height: 27px;
  text-align: left;
  white-space:nowrap;
}
.logo {
  width: 166px;
  height: 73px;
  margin-left: -20px;
  margin-top: -20px;
  margin-bottom: 11px;
  background: url('../../assets/logo_white.png') no-repeat;
  background-size: 100%;
}
.footer-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footer-left {
  width: 540px;
  display: flex;
  flex-direction: column;
  justify-content: left;
}
.footer-right {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.footer-menu-title {
  width: 72px;
  margin-left: 79px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  white-space:nowrap;
  color: #FFFFFF;
}
.footer-menu-sub-title {
  width: 72px;
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #c4c4c4;
  line-height: 25px;
  margin-bottom: 22px;
  white-space:nowrap;
}
a:hover {
  color: #FFFFFF;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  cursor: pointer;
}

</style>