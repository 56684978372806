<template>
  <div class="main-container">
    <swiper
      id="homeSwiper"
      ref="homeSwiper"
      :options="homeSwiperOptions"
      class="homeSwiper"
    >
      <swiper-slide class="swiper-side">
        <Header :isTransparent="true" @scroll="scrollEvent" />
        <div class="home" v-cloak>
          <Banner />
          <div class="home-intro-container" v-cloak>
            <div class="home-intro" v-cloak>
              京孚信息是一家专业的软件人才派遣、科技服务咨询提供商。<br />
              公司致力于为全国客户提供世界领先的IT人力派遣、解决方案以及外包服务，在金融服务、高科技、电信、旅游交通、能源、生命科学、制造、零售与分销等领域积累了丰富的行业经验，主要客户涵盖众多大中型中国企业。
            </div>
          </div>
          <div class="bottom-mask" v-cloak></div>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-side">
        <div ref="introduce" v-cloak>
          <Introduce />
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-side">
        <div ref="business" v-cloak>
          <Business />
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-side">
        <div ref="link" v-cloak>
          <LinkDe />
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-side">
        <div ref="cre" v-cloak>
          <Credentials />
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-side">
        <Footer @scroll="scrollEvent" class="footer" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

import Header from '../../components/Header'
import Banner from '../../components/Banner'
import Business from './business'
import Introduce from './introduce'
import LinkDe from './linkDe'
import Footer from '../../components/Footer'
import Credentials from './credentials.vue'

export default {
  components: {
    Header,
    Banner,
    Business,
    Introduce,
    LinkDe,
    Footer,
    Credentials,
    swiper,
    swiperSlide,
  },

  data() {
    return {
      homeSwiperOptions: {
        direction: 'vertical',
        slidesPerView: 1,
        spaceBetween: 0,
        mousewheel: true,
      },
    }
  },
  methods: {
    scrollEvent(query) {
      if (query && query.id) {
        // this.$refs[query.id].scrollIntoView();
        if (query.id == 'introduce') {
          this.swiper.slideTo(1, 500, false)
        }
        if (query.id == 'business') {
          this.swiper.slideTo(2, 500, false)
        }
      } else {
        this.swiper.slideTo(0, 500, false)
      }
    },
  },
  mounted() {
    this.scrollEvent(this.$route.query)
  },
  computed: {
    swiper() {
      return this.$refs.homeSwiper.swiper
    },
  },
}
</script>

<style scoped>
.homeSwiper {
  width: 100%;
  height: 100%;
  z-index: 2;
}
.swiper-side {
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.main-container {
  height: 100vh;
}

.home {
  width: 100%;
  height: 100%;
  position: relative;
}

.home-intro-container {
  width: 100%;
  /* height: 400px; */
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 99;
  bottom: 20px;
}
.home-intro {
  width: 872px;
  height: 220px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #898989;
  line-height: 50px;
  text-align: center;
}

.bottom-mask {
  position: absolute;
  right: 0;
  bottom: -408px;
  width: 632px;
  height: 808px;
  z-index: 0;
  background: url('../../assets/background_mask.png') no-repeat;
  background-size: 100%;
}

.header {
  z-index: 999;
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
