<template>
  <div class="business-container" :class="_isMobile() ? 'introduce-mobile':''" v-cloak @click="clickEvent">
    <div  class="left-img"></div>
    <div class="right-img"></div>
    <div class="bus-content" v-cloak>
      <div class="bus-top" v-cloak>
        <div class="bus-top-left">
          <div class="bus-top-right-block"></div>
          <div class="bus-top-left-txt01">业务领域</div>
          <div class="bus-top-left-txt02">我 —— 们 —— 可 —— 以 —— 做 —— 什 —— 么</div>
          <div class="bus-top-left-txt03">Business<br />Area</div>
        </div>
        <div class="bus-top-right">
          <div class="bus-top-right-block"></div>
          <div class="bus-top-right-flag"></div>
          <div class="bus-top-right-txt">
            通过大数据、云计算和物联网等技术创新商业、城市及社会发展方式，践行可持续核心发展观，不断完善面向全球客户的长期优质服务能力体系。积极响应国家政策，应对未来发展，参与创造使商业与社会、自然更加融合、更加智能的良性生态环境。
          </div>
          <div class="more-gray bus-top-more"></div>
        </div>
      </div>
      <div v-cloak class="bus-mid" @click="clickEvent">
        <div v-cloak class="bus-mid-item" 
        :class="[mini_last ? (index == 3 ? 'bus-mid-item-mini':''):mini_first ? (index == 0 ? 'bus-mid-item-mini': ''):'',get_class(index)]"
         v-for="(item,index) in bus_list" :key="index" 
         @mouseenter="mouseenterEvent(item,index)" 
         @mouseleave="mouseleaveEvent(item)"
         @click="clickEvent"
         >
            <div class="bus-mid-item-icon">
               <img  :src="require('../../assets/'+item.icon)"/>
            </div>
            <div class="bus-mid-item-line"></div>
            <div class="bus-mid-item-title">{{item.title}}</div>
            <transition name="fade-left" mode="out-in">
              <div class="hover-content" v-if="item.hover" v-cloak>
                <img  :src="item.hover_url" />
              </div>
            </transition>
        </div>
        
      </div>
      <div class="bus-bottom"></div>
    </div>
    <div class="next-button"></div>
  </div>
</template>

<script>

import item_01 from '../../assets/bus-area-01.png'
import item_02 from '../../assets/bus-area-02.png'
import item_03 from '../../assets/bus-area-03.png'
import item_04 from '../../assets/bus-area-04.png'


// function debounce(func,delay){
//     let timer = null
//     return function() {
//         if(timer){
//             clearTimeout(timer) 
//         }
//         timer = setTimeout(func,delay) 
//     }
// }



export default {

  data() {
    return {
      mini_last: false,
      mini_first: false,
      current_index: '',
      isAnimate: false,
      bus_list: [{
        icon: 'zplc_pro.png',
        title: '招聘流程外包\nRPO',
        intro: '',
        hover: false,
        hover_url: item_01,
      },{
         icon: 'ywlcwb_bpo.png',
        title: '业务流程外包\nBPO',
        intro: '',
        hover: false,
        hover_url: item_02,
      },{
         icon: 'ltfw.png',
        title: '猎头服务\nHeadHubting',
        intro: '',
        hover: false,
        hover_url: item_03,
      }
      ,{
        icon: 'xxjsfwwb.png',
        title: '灵活用工\nFlexible Staffing',
        intro: '',
        hover: false,
        hover_url: item_04,
      }
      ],
    }
  },

  methods: {
      _isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
      },
      clickEvent() {
      },

      mouseenterEvent(item,index) {
        // debounce(this.open(item,index),500)
        if (this.isAnimate) {
          return;
        }
        this.isAnimate = true;
        this.current_index = index + 1;
        item.hover = true;
        this.mini_last = index != 3;
        this.mini_first = index == 3;
        setTimeout(() => {
          this.isAnimate = false;
        }, 500);
      },

      open(item,index) {
        item.hover = true;
        this.mini_last = index != 3;
        this.mini_first = index == 3;
      },

      mouseleaveEvent(item) {
        item.hover = false;
        this.mini_first = false;
        this.mini_last = false;
         this.current_index = '';
      },
      get_class(index) {
        if (this.current_index == index + 1) {
          return 'bus-mid-item-hover'
        }
        return ''
      }
  }
}
</script>

<style scoped>
  @font-face {
    font-family: Bebas;
    src: url('../../assets/font/BEBAS___.TTF') format("truetype");
  }

  div {
    box-sizing: border-box;
  }
  .introduce-mobile {
    height: 1080px !important;
  }

  .business-container {
    width: 100%;
    height: 100vh;
    background: #F5F6F7;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    z-index: 0;
  }
  .bus-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    flex-shrink: 0;
    z-index: 5;
    max-width: 1920px;
    
  }
  .bus-top {
    display: flex;
    flex-direction: row;
  }
  .bus-top-left {
    height: 373px;
    width: 450px;
    text-align: left;
  }
  .bus-top-left-txt01 {
    width: auto;
    height: 48px;
    font-size: 34px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 48px;
  }
  .bus-top-left-txt02 {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #898989;
    line-height: 22px;
  }
  .bus-top-left-txt03 {
    width: 260px;
    height: 140px;
    font-size: 60px;
    font-family: Bebas;
    color: #aaa;
    opacity: 0.15;
    line-height: 70px;

  }
  .bus-top-right {
    width: 730px;
    height: 373px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .bus-top-right-block {
    width: 100%;
    height: 158px;
  }
  .bus-top-right-flag {
    width: 53px;
    height: 10px;
    background: #F5A100;
  }
  .bus-top-right-txt {
    width: 679px;
    height: 102px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #898989;
    line-height: 34px;
    margin-top: 5px;
    text-align: left;
  }
  .bus-mid {
    width: 1198px;
    height: 320px;
    margin-top: 134px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    z-index: 10;
  }
  .bus-mid-item {
    width: 291px;
    height: 320px;
    background: #FFFFFF;
    border-radius: 4px;
    margin-right: 13px;
    box-sizing: border-box;
    padding-top: 86px;
    padding-bottom: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: width 0.35s linear; 
    z-index: 12;
  }
  .bus-mid-item {
    min-width: 80px;
  }
  .bus-mid-item-icon {
    width: 57px;
    height: 70px;
  }
  .bus-mid-item-line {
    width: 204px;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 30px;
    background-image: linear-gradient(to right,rgba(200, 200, 200, 0), rgba(194, 194, 194, 1), rgba(151, 151, 151, 0));
    /* border: 1px solid; */
  }
  .bus-mid-item-title {
    width: auto;
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #666666;
    line-height: 33px;
    text-align: center;
    white-space: pre-wrap;
  }
  .left-img {
    position:absolute;
    left: 0;
    top: 0;
    width: 814px;
    height: 373px;
    z-index: -1;
    background: url('../../assets/bg_mask_02.png') no-repeat;
    background-size: 100%;
  }
  .right-img {
    position:absolute;
    right: 0;
    bottom: 0;
    width: 810px;
    height: 208px;
    background: url('../../assets/bg_mask_03.png') no-repeat;
    background-size: 100%;
    z-index: 0;
  }

  .next-button {
    width: 38px;
    height: 20px;
    position: absolute;
    bottom: 36px;
    background:url('../../assets/next-page.png') no-repeat;
    background-size: 100%;
  }

  .hover-content {
    width: 503px;
    height: 320px;
    background: #C30F23;
    border-radius: 4px;
    position:absolute;
    left: 0;
    top: 0;
    z-index: 10;
  }
  .bus-mid-item-hover {
    width: 503px;
    height: 320px;
  }
  .bus-mid-item-mini {
    width: 80px;
    height: 320px;
    overflow: hidden;
    padding-top: 440px;
  }
  .bus-top-more {
    margin-top: 8px;
    background: url('../../assets/more-gray.png') no-repeat;
    background-size: 100%;
  }

  img {
    width: 100%;
    height: 100%;
  }

.fade-left-enter-active {
  animation:fade-left 0.35s linear
}
.fade-left-leave-active {
  animation: fade-left 0.35s linear reverse;
}
@keyframes fade-left {
0% {
    transform: translateX(-100%);
  }
  
  100% {
    transform: translateY(0);
  }
}


</style>